import * as React from 'react';
import { SVGProps } from 'react';

const SvgExchangeHistoryIcon = (props: SVGProps) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2 4v6h6" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M4.51 15a9 9 0 1 0 2.13-9.36L2 10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 12.716v-3.82a.896.896 0 1 1 1.793 0v2.916a1 1 0 0 0 .533.884l2.869 1.517a.817.817 0 0 1-.763 1.445l-3.899-2.057a1 1 0 0 1-.533-.885Z"
        fill="#fff"
      />
    </svg>
  );
};
export default SvgExchangeHistoryIcon;
