import * as React from 'react';
import { SVGProps } from 'react';

const SvgCryptoImage = (props: SVGProps) => {
  return (
    <svg
      width={349}
      height={274}
      viewBox="0 0 349 274"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={11} cy={161} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={19} cy={161} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={27} cy={161} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={35} cy={161} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={43} cy={161} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={51} cy={161} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={59} cy={161} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={67} cy={161} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={11} cy={169} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={19} cy={169} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={27} cy={169} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={35} cy={169} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={43} cy={169} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={51} cy={169} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={59} cy={169} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={67} cy={169} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={11} cy={177} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={19} cy={177} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={27} cy={177} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={35} cy={177} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={43} cy={177} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={51} cy={177} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={59} cy={177} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={67} cy={177} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={11} cy={185} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={19} cy={185} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={27} cy={185} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={35} cy={185} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={43} cy={185} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={51} cy={185} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={59} cy={185} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={67} cy={185} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={11} cy={193} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={19} cy={193} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={27} cy={193} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={35} cy={193} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={43} cy={193} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={51} cy={193} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={59} cy={193} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={67} cy={193} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={11} cy={201} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={19} cy={201} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={27} cy={201} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={35} cy={201} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={43} cy={201} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={51} cy={201} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={59} cy={201} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={67} cy={201} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={11} cy={209} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={19} cy={209} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={27} cy={209} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={35} cy={209} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={43} cy={209} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={51} cy={209} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={59} cy={209} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={67} cy={209} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={11} cy={217} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={19} cy={217} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={27} cy={217} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={35} cy={217} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={43} cy={217} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={51} cy={217} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={59} cy={217} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={67} cy={217} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={11} cy={225} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={19} cy={225} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={27} cy={225} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={35} cy={225} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={43} cy={225} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={51} cy={225} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={59} cy={225} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={67} cy={225} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={11} cy={233} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={19} cy={233} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={27} cy={233} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={35} cy={233} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={43} cy={233} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={51} cy={233} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={59} cy={233} r={2} fill="#fff" fillOpacity={0.2} />
      <circle cx={67} cy={233} r={2} fill="#fff" fillOpacity={0.2} />
      <circle
        cx={311}
        cy={21}
        r={2}
        transform="rotate(90 311 21)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={311}
        cy={29}
        r={2}
        transform="rotate(90 311 29)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={311}
        cy={37}
        r={2}
        transform="rotate(90 311 37)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={311}
        cy={45}
        r={2}
        transform="rotate(90 311 45)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={311}
        cy={53}
        r={2}
        transform="rotate(90 311 53)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={311}
        cy={61}
        r={2}
        transform="rotate(90 311 61)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={311}
        cy={69}
        r={2}
        transform="rotate(90 311 69)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={311}
        cy={77}
        r={2}
        transform="rotate(90 311 77)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={303}
        cy={21}
        r={2}
        transform="rotate(90 303 21)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={303}
        cy={29}
        r={2}
        transform="rotate(90 303 29)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={303}
        cy={37}
        r={2}
        transform="rotate(90 303 37)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={303}
        cy={45}
        r={2}
        transform="rotate(90 303 45)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={303}
        cy={53}
        r={2}
        transform="rotate(90 303 53)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={303}
        cy={61}
        r={2}
        transform="rotate(90 303 61)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={303}
        cy={69}
        r={2}
        transform="rotate(90 303 69)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={303}
        cy={77}
        r={2}
        transform="rotate(90 303 77)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={295}
        cy={21}
        r={2}
        transform="rotate(90 295 21)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={295}
        cy={29}
        r={2}
        transform="rotate(90 295 29)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={295}
        cy={37}
        r={2}
        transform="rotate(90 295 37)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={295}
        cy={45}
        r={2}
        transform="rotate(90 295 45)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={295}
        cy={53}
        r={2}
        transform="rotate(90 295 53)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={295}
        cy={61}
        r={2}
        transform="rotate(90 295 61)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={295}
        cy={69}
        r={2}
        transform="rotate(90 295 69)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={295}
        cy={77}
        r={2}
        transform="rotate(90 295 77)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={287}
        cy={21}
        r={2}
        transform="rotate(90 287 21)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={287}
        cy={29}
        r={2}
        transform="rotate(90 287 29)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={287}
        cy={37}
        r={2}
        transform="rotate(90 287 37)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={287}
        cy={45}
        r={2}
        transform="rotate(90 287 45)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={287}
        cy={53}
        r={2}
        transform="rotate(90 287 53)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={287}
        cy={61}
        r={2}
        transform="rotate(90 287 61)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={287}
        cy={69}
        r={2}
        transform="rotate(90 287 69)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={287}
        cy={77}
        r={2}
        transform="rotate(90 287 77)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={279}
        cy={21}
        r={2}
        transform="rotate(90 279 21)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={279}
        cy={29}
        r={2}
        transform="rotate(90 279 29)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={279}
        cy={37}
        r={2}
        transform="rotate(90 279 37)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={279}
        cy={45}
        r={2}
        transform="rotate(90 279 45)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={279}
        cy={53}
        r={2}
        transform="rotate(90 279 53)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={279}
        cy={61}
        r={2}
        transform="rotate(90 279 61)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={279}
        cy={69}
        r={2}
        transform="rotate(90 279 69)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={279}
        cy={77}
        r={2}
        transform="rotate(90 279 77)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={271}
        cy={21}
        r={2}
        transform="rotate(90 271 21)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={271}
        cy={29}
        r={2}
        transform="rotate(90 271 29)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={271}
        cy={37}
        r={2}
        transform="rotate(90 271 37)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={271}
        cy={45}
        r={2}
        transform="rotate(90 271 45)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={271}
        cy={53}
        r={2}
        transform="rotate(90 271 53)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={271}
        cy={61}
        r={2}
        transform="rotate(90 271 61)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={271}
        cy={69}
        r={2}
        transform="rotate(90 271 69)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={271}
        cy={77}
        r={2}
        transform="rotate(90 271 77)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={263}
        cy={21}
        r={2}
        transform="rotate(90 263 21)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={263}
        cy={29}
        r={2}
        transform="rotate(90 263 29)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={263}
        cy={37}
        r={2}
        transform="rotate(90 263 37)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={263}
        cy={45}
        r={2}
        transform="rotate(90 263 45)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={263}
        cy={53}
        r={2}
        transform="rotate(90 263 53)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={263}
        cy={61}
        r={2}
        transform="rotate(90 263 61)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={263}
        cy={69}
        r={2}
        transform="rotate(90 263 69)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={263}
        cy={77}
        r={2}
        transform="rotate(90 263 77)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={255}
        cy={21}
        r={2}
        transform="rotate(90 255 21)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={255}
        cy={29}
        r={2}
        transform="rotate(90 255 29)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={255}
        cy={37}
        r={2}
        transform="rotate(90 255 37)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={255}
        cy={45}
        r={2}
        transform="rotate(90 255 45)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={255}
        cy={53}
        r={2}
        transform="rotate(90 255 53)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={255}
        cy={61}
        r={2}
        transform="rotate(90 255 61)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={255}
        cy={69}
        r={2}
        transform="rotate(90 255 69)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={255}
        cy={77}
        r={2}
        transform="rotate(90 255 77)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={247}
        cy={21}
        r={2}
        transform="rotate(90 247 21)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={247}
        cy={29}
        r={2}
        transform="rotate(90 247 29)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={247}
        cy={37}
        r={2}
        transform="rotate(90 247 37)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={247}
        cy={45}
        r={2}
        transform="rotate(90 247 45)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={247}
        cy={53}
        r={2}
        transform="rotate(90 247 53)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={247}
        cy={61}
        r={2}
        transform="rotate(90 247 61)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={247}
        cy={69}
        r={2}
        transform="rotate(90 247 69)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={247}
        cy={77}
        r={2}
        transform="rotate(90 247 77)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={239}
        cy={21}
        r={2}
        transform="rotate(90 239 21)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={239}
        cy={29}
        r={2}
        transform="rotate(90 239 29)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={239}
        cy={37}
        r={2}
        transform="rotate(90 239 37)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={239}
        cy={45}
        r={2}
        transform="rotate(90 239 45)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={239}
        cy={53}
        r={2}
        transform="rotate(90 239 53)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={239}
        cy={61}
        r={2}
        transform="rotate(90 239 61)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={239}
        cy={69}
        r={2}
        transform="rotate(90 239 69)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={239}
        cy={77}
        r={2}
        transform="rotate(90 239 77)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={311}
        cy={85}
        r={2}
        transform="rotate(90 311 85)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={311}
        cy={93}
        r={2}
        transform="rotate(90 311 93)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={311}
        cy={101}
        r={2}
        transform="rotate(90 311 101)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={311}
        cy={109}
        r={2}
        transform="rotate(90 311 109)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={311}
        cy={117}
        r={2}
        transform="rotate(90 311 117)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={311}
        cy={125}
        r={2}
        transform="rotate(90 311 125)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={311}
        cy={133}
        r={2}
        transform="rotate(90 311 133)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={311}
        cy={141}
        r={2}
        transform="rotate(90 311 141)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={303}
        cy={85}
        r={2}
        transform="rotate(90 303 85)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={303}
        cy={93}
        r={2}
        transform="rotate(90 303 93)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={303}
        cy={101}
        r={2}
        transform="rotate(90 303 101)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={303}
        cy={109}
        r={2}
        transform="rotate(90 303 109)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={303}
        cy={117}
        r={2}
        transform="rotate(90 303 117)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={303}
        cy={125}
        r={2}
        transform="rotate(90 303 125)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={303}
        cy={133}
        r={2}
        transform="rotate(90 303 133)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={303}
        cy={141}
        r={2}
        transform="rotate(90 303 141)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={295}
        cy={85}
        r={2}
        transform="rotate(90 295 85)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={295}
        cy={93}
        r={2}
        transform="rotate(90 295 93)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={295}
        cy={101}
        r={2}
        transform="rotate(90 295 101)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={295}
        cy={109}
        r={2}
        transform="rotate(90 295 109)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={295}
        cy={117}
        r={2}
        transform="rotate(90 295 117)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={295}
        cy={125}
        r={2}
        transform="rotate(90 295 125)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={295}
        cy={133}
        r={2}
        transform="rotate(90 295 133)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={295}
        cy={141}
        r={2}
        transform="rotate(90 295 141)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={287}
        cy={85}
        r={2}
        transform="rotate(90 287 85)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={287}
        cy={93}
        r={2}
        transform="rotate(90 287 93)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={287}
        cy={101}
        r={2}
        transform="rotate(90 287 101)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={287}
        cy={109}
        r={2}
        transform="rotate(90 287 109)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={287}
        cy={117}
        r={2}
        transform="rotate(90 287 117)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={287}
        cy={125}
        r={2}
        transform="rotate(90 287 125)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={287}
        cy={133}
        r={2}
        transform="rotate(90 287 133)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={287}
        cy={141}
        r={2}
        transform="rotate(90 287 141)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={279}
        cy={85}
        r={2}
        transform="rotate(90 279 85)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={279}
        cy={93}
        r={2}
        transform="rotate(90 279 93)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={279}
        cy={101}
        r={2}
        transform="rotate(90 279 101)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={279}
        cy={109}
        r={2}
        transform="rotate(90 279 109)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={279}
        cy={117}
        r={2}
        transform="rotate(90 279 117)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={279}
        cy={125}
        r={2}
        transform="rotate(90 279 125)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={279}
        cy={133}
        r={2}
        transform="rotate(90 279 133)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={279}
        cy={141}
        r={2}
        transform="rotate(90 279 141)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={271}
        cy={85}
        r={2}
        transform="rotate(90 271 85)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={271}
        cy={93}
        r={2}
        transform="rotate(90 271 93)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={271}
        cy={101}
        r={2}
        transform="rotate(90 271 101)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={271}
        cy={109}
        r={2}
        transform="rotate(90 271 109)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={271}
        cy={117}
        r={2}
        transform="rotate(90 271 117)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={271}
        cy={125}
        r={2}
        transform="rotate(90 271 125)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={271}
        cy={133}
        r={2}
        transform="rotate(90 271 133)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={271}
        cy={141}
        r={2}
        transform="rotate(90 271 141)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={263}
        cy={85}
        r={2}
        transform="rotate(90 263 85)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={263}
        cy={93}
        r={2}
        transform="rotate(90 263 93)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={263}
        cy={101}
        r={2}
        transform="rotate(90 263 101)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={263}
        cy={109}
        r={2}
        transform="rotate(90 263 109)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={263}
        cy={117}
        r={2}
        transform="rotate(90 263 117)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={263}
        cy={125}
        r={2}
        transform="rotate(90 263 125)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={263}
        cy={133}
        r={2}
        transform="rotate(90 263 133)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={263}
        cy={141}
        r={2}
        transform="rotate(90 263 141)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={255}
        cy={85}
        r={2}
        transform="rotate(90 255 85)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={255}
        cy={93}
        r={2}
        transform="rotate(90 255 93)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={255}
        cy={101}
        r={2}
        transform="rotate(90 255 101)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={255}
        cy={109}
        r={2}
        transform="rotate(90 255 109)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={255}
        cy={117}
        r={2}
        transform="rotate(90 255 117)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={255}
        cy={125}
        r={2}
        transform="rotate(90 255 125)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={255}
        cy={133}
        r={2}
        transform="rotate(90 255 133)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={255}
        cy={141}
        r={2}
        transform="rotate(90 255 141)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={247}
        cy={85}
        r={2}
        transform="rotate(90 247 85)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={247}
        cy={93}
        r={2}
        transform="rotate(90 247 93)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={247}
        cy={101}
        r={2}
        transform="rotate(90 247 101)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={247}
        cy={109}
        r={2}
        transform="rotate(90 247 109)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={247}
        cy={117}
        r={2}
        transform="rotate(90 247 117)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={247}
        cy={125}
        r={2}
        transform="rotate(90 247 125)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={247}
        cy={133}
        r={2}
        transform="rotate(90 247 133)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={247}
        cy={141}
        r={2}
        transform="rotate(90 247 141)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={239}
        cy={85}
        r={2}
        transform="rotate(90 239 85)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={239}
        cy={93}
        r={2}
        transform="rotate(90 239 93)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={239}
        cy={101}
        r={2}
        transform="rotate(90 239 101)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={239}
        cy={109}
        r={2}
        transform="rotate(90 239 109)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={239}
        cy={117}
        r={2}
        transform="rotate(90 239 117)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={239}
        cy={125}
        r={2}
        transform="rotate(90 239 125)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={239}
        cy={133}
        r={2}
        transform="rotate(90 239 133)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <circle
        cx={239}
        cy={141}
        r={2}
        transform="rotate(90 239 141)"
        fill="#fff"
        fillOpacity={0.2}
      />
      <path
        d="m185.372 142.71-8.949-11.119a1.064 1.064 0 0 0-1.825.285l-1.452 3.792c-15.722-4.696-24.326 9.004-24.326 9.004l6.028 4.241c6.549-10.127 15.892-6.861 15.892-6.861l-1.266 3.288a1.071 1.071 0 0 0 .17 1.057 1.06 1.06 0 0 0 1.003.374l14.1-2.368a1.098 1.098 0 0 0 .625-1.693Zm-5.48 41.149c-6.121 8.84-14.111 6.971-15.777 6.461l1.797-4.285a.487.487 0 0 0-.284-.659.484.484 0 0 0-.264-.02l-16.138 2.192a.486.486 0 0 0-.324.783l9.788 12.982a.492.492 0 0 0 .201.154.496.496 0 0 0 .643-.263l1.918-4.587c15.382 4.883 24.287-8.335 24.287-8.335l-5.847-4.423ZM147.371 72.478c4.583-9.626.495-21.146-9.132-25.73-9.627-4.584-21.147-.495-25.731 9.132-4.583 9.627-.495 21.147 9.132 25.73 9.627 4.584 21.147.495 25.731-9.132Z"
        fill="#E0E0E0"
      />
      <path
        d="M147.541 64.079c.873-10.627-7.033-19.95-17.659-20.823-10.627-.874-19.95 7.032-20.824 17.66-.873 10.626 7.033 19.949 17.66 20.822 10.626.874 19.949-7.032 20.823-17.66Z"
        fill="#EBEBEB"
      />
      <path
        d="M133.001 75.843c7.644-2.749 11.667-11.021 8.986-18.477-2.682-7.456-11.052-11.272-18.696-8.523-7.644 2.749-11.667 11.021-8.985 18.477 2.681 7.456 11.051 11.272 18.695 8.523Z"
        fill="#F5F5F5"
      />
      <path
        d="M132.983 75.777a14.703 14.703 0 0 1-18.659-8.472c.044.137.082.269.131.4a14.707 14.707 0 0 0 18.821 8.877 14.706 14.706 0 0 0 8.848-18.834c-.044-.131-.099-.257-.148-.389a14.707 14.707 0 0 1-8.993 18.418Z"
        fill="#FAFAFA"
      />
      <path
        d="M123.311 48.893a14.704 14.704 0 0 1 18.665 8.466l-.132-.4a14.702 14.702 0 0 0-18.815-8.858 14.704 14.704 0 0 0-8.859 18.815c.049.132.104.263.154.39a14.695 14.695 0 0 1 8.987-18.413Z"
        fill="#E0E0E0"
      />
      <path
        d="M136.781 63.678c-.702-1.946-2.083-2.872-3.628-2.894a3.95 3.95 0 0 0 .449-3.874c-.685-1.913-2.236-2.883-4.417-2.614l-.843-2.356-2.192.783.789 2.192-1.239.444-.783-2.192-2.192.784.789 2.192-1.984.712-1.381.499.784 2.192 1.381-.493 3.808 10.587-1.523.548.778 2.192 1.529-.548 1.984-.718.92 2.554 2.192-.784-.882-2.581 1.233-.444.921 2.554 2.192-.784-.949-2.636c2.291-1.227 3.058-3.102 2.264-5.315Zm-11.689-5.64 2.948-1.057c1.644-.592 2.806-.241 3.288 1.145.482 1.387-.192 2.384-1.83 2.976l-3.343 1.2-1.485-4.12.422-.143Zm7.233 9.366-3.567 1.288-.247.088-1.556-4.313 3.836-1.376c1.781-.64 2.987-.323 3.524 1.173.537 1.496-.209 2.493-1.99 3.14Z"
        fill="#E0E0E0"
      />
      <path
        d="M135.718 64.056c-.696-1.94-2.077-2.866-3.623-2.888a3.95 3.95 0 0 0 .45-3.88c-.685-1.907-2.236-2.882-4.417-2.608l-.844-2.362-2.192.783.789 2.192-1.238.444-.789-2.192-2.192.784.789 2.192-1.984.718-1.381.493.784 2.192 1.381-.499 3.836 10.593-1.529.548.783 2.192 1.529-.548 1.984-.712.921 2.548 2.192-.784-.943-2.57 1.233-.444.921 2.548 2.192-.783-.948-2.63c2.329-1.24 3.096-3.113 2.296-5.327Zm-11.678-5.634 2.948-1.063c1.644-.586 2.806-.235 3.288 1.151.482 1.387-.186 2.384-1.825 2.97l-3.343 1.206-1.485-4.121.417-.143Zm7.228 9.36-3.568 1.266-.252.088-1.55-4.313 3.836-1.37c1.781-.641 2.986-.329 3.523 1.167.537 1.496-.208 2.521-1.989 3.162Z"
        fill="#EBEBEB"
      />
      <path
        d="M123.864 53.545c.055.142-.761.548-2.055 1.096l.214-.45c.263.614.548 1.36.855 2.165l.148.4-.405.143-.587.213c-1.03.373-1.995.691-2.8.932l.214-.455c.4 1.343.586 2.253.449 2.302-.137.05-.575-.773-1.096-2.06l-.132-.308.346-.148c.778-.323 1.72-.695 2.74-1.068l.592-.208-.258.548c-.285-.817-.548-1.573-.723-2.192l-.105-.356.318-.094c1.321-.41 2.236-.597 2.285-.46Zm3.891-1.354c.077.225-.449.614-1.173.877-.723.263-1.38.296-1.463.072-.082-.225.444-.614 1.173-.877.729-.263 1.381-.29 1.463-.072Zm-2.838 17.701c-.22.082-1.239-2.192-2.269-5.047-1.03-2.855-1.688-5.255-1.463-5.338.224-.082 1.238 2.192 2.268 5.047 1.031 2.855 1.688 5.261 1.464 5.338Zm4.663-8.79c.077.225-.8.745-1.956 1.162-1.156.416-2.192.548-2.247.35-.055-.197.795-.74 1.956-1.156 1.162-.416 2.143-.575 2.247-.356Zm2.406 6.438c.082.225-.795.74-1.957 1.163-1.161.422-2.164.548-2.246.35-.083-.197.794-.74 1.956-1.156 1.162-.416 2.165-.575 2.247-.356Z"
        fill="#FAFAFA"
      />
      <path
        d="M213.999 82.665c9.639-1.648 16.117-10.797 14.47-20.436-1.648-9.639-10.797-16.117-20.436-14.47-9.639 1.648-16.117 10.797-14.47 20.436 1.648 9.64 10.797 16.118 20.436 14.47Z"
        fill="#E0E0E0"
      />
      <path
        d="M219.203 78.48c8.186-5.348 10.487-16.32 5.138-24.507-5.349-8.186-16.321-10.486-24.507-5.137-8.187 5.349-10.487 16.32-5.138 24.507 5.349 8.186 16.321 10.486 24.507 5.138Z"
        fill="#EBEBEB"
      />
      <path
        d="M213.837 75.88c7.012-2.523 10.701-10.111 8.241-16.949-2.461-6.837-10.139-10.335-17.15-7.812-7.011 2.522-10.701 10.11-8.24 16.948 2.46 6.837 10.138 10.335 17.149 7.812Z"
        fill="#F5F5F5"
      />
      <path
        d="M213.813 75.843a13.484 13.484 0 0 1-17.114-7.77c.039.12.077.246.121.367a13.486 13.486 0 0 0 24.894 1.181 13.486 13.486 0 0 0 .489-10.311c-.044-.126-.093-.241-.142-.362a13.492 13.492 0 0 1-8.248 16.895Z"
        fill="#FAFAFA"
      />
      <path
        d="M204.947 51.183a13.48 13.48 0 0 1 17.141 7.765 8.523 8.523 0 0 0-.121-.361 13.486 13.486 0 1 0-25.383 9.13c.044.12.093.235.143.356a13.479 13.479 0 0 1 8.22-16.89Z"
        fill="#E0E0E0"
      />
      <path
        d="M217.293 64.746c-.641-1.786-1.907-2.636-3.326-2.652a3.618 3.618 0 0 0 .416-3.557c-.63-1.753-2.055-2.646-4.05-2.394l-.778-2.192-2 .718.723 2.01-1.134.406-.723-2.01-1.995.717.723 2.011-1.819.658-1.266.455.718 2 1.266-.46 3.496 9.715-1.403.505.718 2 1.403-.504 1.819-.658.839 2.34 2-.718-.844-2.34 1.134-.405.844 2.334 1.995-.718-.866-2.41c2.137-1.097 2.844-2.823 2.11-4.85Zm-10.714-5.173 2.708-.97c1.496-.548 2.575-.219 3.03 1.052.455 1.272-.17 2.192-1.671 2.74l-3.069 1.096-1.381-3.759.383-.159Zm6.631 8.587-3.288 1.178-.23.083-1.419-3.957 3.502-1.26c1.644-.587 2.74-.302 3.227 1.096.488 1.397-.153 2.274-1.792 2.86Z"
        fill="#E0E0E0"
      />
      <path
        d="M216.323 65.091c-.641-1.78-1.907-2.63-3.327-2.652a3.607 3.607 0 0 0 .417-3.557c-.63-1.748-2.055-2.64-4.05-2.394l-.778-2.192-2 .723.723 2.011-1.134.406-.724-2.011-1.994.745.723 2.011-1.819.658-1.272.454.724 1.995 1.266-.455 3.496 9.716-1.403.504.718 1.995 1.403-.504 1.819-.652.839 2.34 2-.724-.822-2.334 1.134-.41.844 2.339 1.995-.718-.866-2.416c2.115-1.124 2.822-2.845 2.088-4.878Zm-10.714-5.167 2.702-.976c1.502-.548 2.581-.219 3.036 1.058.455 1.277-.17 2.192-1.644 2.74l-3.069 1.096-1.359-3.781.334-.137Zm6.615 8.576-3.288 1.178-.23.082-1.42-3.956 3.502-1.255c1.644-.592 2.74-.302 3.228 1.096.487 1.397-.148 2.274-1.792 2.855Z"
        fill="#EBEBEB"
      />
      <path
        d="M205.451 55.452c.049.137-.691.548-1.875 1.052l.214-.455c.241.548.515 1.25.789 1.99l.148.394-.405.148-.548.197c-.943.34-1.831.63-2.576.85l.214-.455c.361 1.233.548 2.066.389 2.115-.159.05-.548-.696-1.052-1.874l-.132-.307.345-.148a44.327 44.327 0 0 1 2.521-.986l.548-.192-.257.548a41.567 41.567 0 0 1-.658-2.033l-.104-.362.318-.093c1.222-.367 2.071-.526 2.121-.389Zm3.567-1.244c.077.225-.394.597-1.063.839-.668.24-1.271.257-1.353.032-.083-.224.394-.597 1.063-.838.668-.241 1.271-.252 1.353-.033Zm-2.603 16.237c-.219.083-1.167-1.989-2.115-4.62-.948-2.63-1.529-4.822-1.31-4.931.219-.11 1.173 1.989 2.115 4.62.943 2.63 1.535 4.85 1.31 4.931Zm4.275-8.061c.082.225-.718.713-1.781 1.096-1.063.384-1.989.515-2.072.29-.082-.224.718-.712 1.781-1.095 1.063-.384 1.995-.515 2.072-.29Zm2.208 5.902c.082.225-.718.718-1.781 1.096-1.063.378-1.989.515-2.072.29-.082-.224.718-.712 1.781-1.095 1.063-.384 1.995-.51 2.072-.29Z"
        fill="#FAFAFA"
      />
      <path
        d="M169.699 62.439c8.586 0 15.547-6.96 15.547-15.547 0-8.586-6.961-15.547-15.547-15.547s-15.547 6.961-15.547 15.547 6.961 15.547 15.547 15.547Z"
        fill="#E0E0E0"
      />
      <path
        d="M171.552 59.934c8.5-1.116 14.486-8.912 13.37-17.412-1.116-8.5-8.912-14.487-17.413-13.37-8.5 1.115-14.486 8.911-13.37 17.412 1.116 8.5 8.912 14.486 17.413 13.37Z"
        fill="#EBEBEB"
      />
      <path
        d="M180.523 49.614c2.576-5.837-.188-12.71-6.173-15.353-5.986-2.643-12.928-.054-15.504 5.783-2.577 5.836.187 12.71 6.173 15.353 5.986 2.642 12.927.053 15.504-5.783Z"
        fill="#F5F5F5"
      />
      <path
        d="M165.03 55.348a11.83 11.83 0 0 1-6.176-15.3l-.148.301a11.845 11.845 0 0 0 6.05 15.618 11.843 11.843 0 0 0 15.618-6.05c.049-.104.088-.208.131-.312a11.838 11.838 0 0 1-15.475 5.743Z"
        fill="#FAFAFA"
      />
      <path
        d="M174.325 34.3a11.848 11.848 0 0 1 6.181 15.305c.049-.104.099-.203.142-.307a11.837 11.837 0 0 0-2.233-13.008 11.857 11.857 0 0 0-8.319-3.671 11.842 11.842 0 0 0-11.115 7.116c-.044.099-.083.209-.127.313a11.831 11.831 0 0 1 15.471-5.749Z"
        fill="#E0E0E0"
      />
      <path
        d="M173.968 50.416c.669-1.518.378-2.828-.509-3.699a3.178 3.178 0 0 0 2.416-2.011 3.082 3.082 0 0 0-1.123-3.973l.811-1.847-1.699-.773-.756 1.721-.97-.427.756-1.72-1.704-.752-.756 1.715-1.551-.684-1.096-.477-.751 1.704 1.096.477-3.661 8.297-1.194-.548-.756 1.704 1.2.548 1.55.685-.882 1.994 1.71.757.877-2 .97.427-.883 2 1.705.751.909-2.06c2.033.575 3.524-.088 4.291-1.809Zm-3.682-9.776 2.307 1.008c1.282.548 1.77 1.425 1.293 2.51-.477 1.085-1.436 1.282-2.74.718l-2.619-1.157 1.424-3.227.335.148Zm-.987 9.48-2.789-1.233-.197-.087 1.49-3.382 2.987 1.321c1.397.62 1.923 1.469 1.408 2.641-.515 1.173-1.501 1.337-2.899.724v.016Z"
        fill="#E0E0E0"
      />
      <path
        d="M173.135 50.038c.674-1.523.384-2.828-.509-3.704a3.175 3.175 0 0 0 2.422-2.006 3.078 3.078 0 0 0-1.129-3.979l.816-1.846-1.704-.751-.756 1.704-.97-.427.756-1.716-1.704-.756-.756 1.72-1.557-.69-1.096-.476-.756 1.715 1.096.482-3.66 8.291-1.195-.548-.751 1.721 1.222.548 1.551.685-.882 1.995 1.704.756.882-1.995.97.428-.904 1.967 1.704.75.91-2.06c2.044.592 3.535-.076 4.296-1.808Zm-3.655-9.776 2.313 1.019c1.282.548 1.77 1.42 1.288 2.504-.483 1.085-1.431 1.288-2.713.724l-2.62-1.162 1.425-3.222.307.137Zm-.981 9.474-2.789-1.232-.197-.088 1.49-3.376 2.987 1.315c1.397.62 1.923 1.469 1.408 2.642-.515 1.172-1.529 1.353-2.926.74h.027Z"
        fill="#EBEBEB"
      />
      <path
        d="M172.061 37.324c-.06.132-.784-.043-1.847-.433l.466-.18c-.181.51-.417 1.096-.691 1.748l-.169.389-.395-.176-.46-.197a35.199 35.199 0 0 1-2.143-1.03l.466-.181c-.548 1.003-.943 1.644-1.096 1.562-.154-.082.044-.784.427-1.847l.11-.307.356.126c.641.23 1.398.548 2.192.893l.455.209-.548.213c.285-.63.548-1.205.822-1.687l.175-.335.291.154c1.03.526 1.649.942 1.589 1.08Zm3.019 1.365c-.093.219-.635.192-1.205-.06-.57-.253-.954-.63-.855-.85.098-.22.636-.186 1.205.066.57.252.954.63.855.844Zm-11.486 8.751c-.213-.098.417-1.994 1.403-4.241.987-2.247 1.973-3.984 2.192-3.891.219.093-.411 1.995-1.403 4.242-.991 2.246-1.972 3.983-2.192 3.89Zm7.601-2.537c-.093.214-.909.066-1.819-.334-.91-.4-1.567-.904-1.469-1.096.099-.192.91-.066 1.814.334s1.573.877 1.474 1.096Zm-2.17 5.091c-.099.214-.91.066-1.819-.334-.91-.4-1.568-.905-1.475-1.096.094-.192.91-.066 1.82.334.909.4 1.567.877 1.474 1.096Z"
        fill="#FAFAFA"
      />
      <path
        d="M167.052 209.851a6.17 6.17 0 0 1 4.812-.323 10.86 10.86 0 0 1 4.137 2.647c2.302 2.225 3.743 5.611 4.565 8.696-3.48.702-7.086-1.298-8.845-2.422-2.789-1.786-6.751-6.696-4.669-8.598Zm19.569 11.755a5.244 5.244 0 0 1-2.92-4.384 6.926 6.926 0 0 1 1.863-5.058 2.917 2.917 0 0 1 2.192-1.167 2.242 2.242 0 0 1 1.825 1.359c.3.743.398 1.552.285 2.345-.258 2.74-1.014 5.316-3.234 6.91"
        fill="#F88360"
      />
      <path
        d="M192.25 230.264a3.258 3.258 0 0 1-.461-.471l-1.2-1.332a18.492 18.492 0 0 1-3.359-4.882 11.28 11.28 0 0 1-.937-5.826 7.78 7.78 0 0 1 .427-1.753c.071-.197.148-.345.192-.45a.616.616 0 0 1 .088-.148 10.513 10.513 0 0 0 .444 8.089 19.763 19.763 0 0 0 3.288 4.861l1.145 1.381c.138.167.263.344.373.531Z"
        fill="#263238"
      />
      <path
        d="M170.401 213.326c.087.04.17.09.246.148l.675.46c.586.405 1.43 1.003 2.466 1.742a290.716 290.716 0 0 1 7.962 5.968 283.018 283.018 0 0 1 7.754 6.236c.976.822 1.765 1.486 2.302 1.957l.613.548a1.3 1.3 0 0 1 .203.203 1.6 1.6 0 0 1-.235-.165l-.642-.509c-.575-.472-1.37-1.096-2.345-1.897a473.782 473.782 0 0 0-7.793-6.186 583.202 583.202 0 0 0-7.918-6.028l-2.417-1.798c-.268-.203-.488-.367-.652-.499a1.303 1.303 0 0 1-.219-.18Z"
        fill="#263238"
      />
      <path
        d="M185.329 226.456a12.28 12.28 0 0 0-11.224-.17c-.882.444-1.819 1.2-1.737 2.192.083.992 1.124 1.589 2.077 1.868a10.494 10.494 0 0 0 11.053-3.677"
        fill="#F88360"
      />
      <path
        d="M176.758 227.776c.173-.115.356-.214.548-.296a5.73 5.73 0 0 1 .663-.301c.295-.127.597-.239.904-.334.371-.124.747-.229 1.129-.313.436-.102.876-.181 1.321-.235a13.28 13.28 0 0 1 3.074 0c1.022.125 2.026.37 2.992.729.419.158.829.341 1.228.548a9.63 9.63 0 0 1 1.013.575c.273.169.536.352.789.548.192.142.375.296.548.46.163.129.312.274.444.433-.033.039-.674-.548-1.885-1.304a10.537 10.537 0 0 0-1.014-.548 12.775 12.775 0 0 0-1.216-.521 13.913 13.913 0 0 0-2.943-.696 14.428 14.428 0 0 0-3.025 0 14.374 14.374 0 0 0-2.4.494c-1.365.405-2.148.811-2.17.761Z"
        fill="#263238"
      />
      <path
        d="M146.798 104.723a7.904 7.904 0 0 1 3.968 2.926 4.066 4.066 0 0 1 1.014 2.444c0 .899-.74 1.831-1.644 1.77a2.323 2.323 0 0 1-1.37-.767c-.932-.898-1.82-2.049-3.102-2.192-.734-.082-1.523.187-2.192-.137a1.645 1.645 0 0 1-.773-1.852 2.861 2.861 0 0 1 1.288-1.644 3.226 3.226 0 0 1 2.811-.548Zm2.039-7.124c-.23 1.572 1.354 2.471 2.493 2.455 1.14-.017 2.192-.609 3.162-1.19 2.242-1.326 4.576-2.74 5.935-4.931.439-.65.689-1.409.724-2.192a2.235 2.235 0 0 0-1.053-1.962c-.811-.444-1.814-.187-2.674.142a18.27 18.27 0 0 0-6.713 4.472 6.383 6.383 0 0 0-1.874 3.233m-3.096-.383a4.747 4.747 0 0 0 3.524-2.658 8.371 8.371 0 0 0 .69-4.488 13.842 13.842 0 0 0-1.485-5.146 4.863 4.863 0 0 0-1.266-1.682 2.187 2.187 0 0 0-1.989-.428c-.986.34-1.419 1.474-1.682 2.488a34.437 34.437 0 0 0-.965 5.436c-.148 1.474-.181 3.025.46 4.384.642 1.36 1.732 2.28 2.927 2.083"
        fill="#F88360"
      />
      <path
        d="M135.34 111.792c.167-.108.323-.233.466-.373l1.222-1.063a21.733 21.733 0 0 1 1.885-1.468 18.135 18.135 0 0 1 2.537-1.442 10.174 10.174 0 0 1 2.767-.854 5.276 5.276 0 0 1 2.34.148c.509.154.991.389 1.425.696.151.129.314.243.488.339a3.06 3.06 0 0 0-.428-.416 4.564 4.564 0 0 0-1.435-.784 5.256 5.256 0 0 0-2.417-.208 9.877 9.877 0 0 0-2.839.86c-.897.417-1.757.91-2.57 1.474a20.52 20.52 0 0 0-1.868 1.524c-.51.46-.905.855-1.173 1.123a2.957 2.957 0 0 0-.4.444Z"
        fill="#263238"
      />
      <path
        d="M136.271 110.981c.075-.054.143-.117.203-.186.137-.154.318-.34.548-.548a86.942 86.942 0 0 1 2.006-2.072c1.71-1.726 3.655-3.479 6.483-5.885 2.658-2.258 5.798-4.839 7.995-6.373.493-.357.948-.663 1.348-.932.4-.269.745-.504 1.036-.68l.652-.433a1.22 1.22 0 0 0 .219-.158.922.922 0 0 0-.241.12l-.685.39c-.296.164-.647.388-1.052.646-.406.257-.877.548-1.376.904a89.066 89.066 0 0 0-7.546 5.792c-2.833 2.412-5.277 4.73-6.959 6.489a53.622 53.622 0 0 0-1.946 2.126l-.504.603a1.092 1.092 0 0 0-.181.197Z"
        fill="#263238"
      />
      <path
        d="M140.738 106.279c.197-.187.38-.389.548-.603.475-.57.922-1.165 1.337-1.781a28.49 28.49 0 0 0 1.693-2.844 26.454 26.454 0 0 0 1.644-3.71 20.395 20.395 0 0 0 1.354-7.212c0-.425-.027-.85-.082-1.27 0-.187 0-.362-.061-.549-.06-.186-.06-.296-.087-.427a5.721 5.721 0 0 0-.198-.795c.148.999.234 2.005.258 3.014a21.37 21.37 0 0 1-1.414 7.124 30.009 30.009 0 0 1-1.611 3.688 32.382 32.382 0 0 1-1.644 2.85c-.51.789-.954 1.403-1.271 1.819a4.855 4.855 0 0 0-.466.696ZM263.9 245.46l-61.118-.285a13.316 13.316 0 0 1-9.395-3.941 13.307 13.307 0 0 1-3.856-9.43l.647-153.215a13.31 13.31 0 0 1 3.943-9.399 13.31 13.31 0 0 1 9.434-3.857l61.113.28a13.317 13.317 0 0 1 13.25 13.37l-.646 153.221a13.321 13.321 0 0 1-3.94 9.398 13.299 13.299 0 0 1-9.432 3.858Z"
        fill="#263238"
      />
      <path
        d="m264.58 71.147-11.848-.055a2.943 2.943 0 0 0-2.065.897 2.936 2.936 0 0 0-.823 2.095v2.148a2.952 2.952 0 0 1-.823 2.097 2.944 2.944 0 0 1-2.065.901l-23.098-.11a2.948 2.948 0 0 1-2.866-3.02v-2.147a2.95 2.95 0 0 0-2.866-3.02h-4.165l-10.374-.05a9.313 9.313 0 0 0-8.643 5.721 9.322 9.322 0 0 0-.722 3.569l-.63 149.527a9.308 9.308 0 0 0 2.699 6.602 9.318 9.318 0 0 0 6.579 2.758l60.992.285a9.318 9.318 0 0 0 9.365-9.289l.636-149.55a9.322 9.322 0 0 0-2.702-6.603 9.315 9.315 0 0 0-6.581-2.756Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M234.239 128.442a1.122 1.122 0 0 0-1.528.011l-16.279 15.753a.973.973 0 0 0-.302.698v22.843c0 .211.072.421.21.597a1.12 1.12 0 0 0 1.53.188l31.996-23.883c.042-.032.082-.066.118-.103a.959.959 0 0 0-.028-1.393l-15.717-14.711Zm-3.003-1.537a3.24 3.24 0 0 1 4.452-.037l15.717 14.712a3.118 3.118 0 0 1-.271 4.794l-31.996 23.884c-1.391 1.037-3.383.792-4.464-.578a3.114 3.114 0 0 1-.674-1.933v-22.843c0-.851.349-1.659.957-2.247l16.279-15.752Zm19.437 25.2c-.344-.457-1.033-.571-1.524-.22l-10.279 7.328a1.079 1.079 0 0 0-.227.22.963.963 0 0 0 .227 1.379l10.279 7.328c.184.132.411.206.649.206.628 0 1.072-.486 1.072-1.005v-14.657a.96.96 0 0 0-.197-.579Zm-2.754-1.973c1.412-1.007 3.398-.721 4.45.674.408.54.631 1.198.631 1.878v14.657c0 1.777-1.47 3.152-3.202 3.152a3.239 3.239 0 0 1-1.879-.6l-10.279-7.328a3.12 3.12 0 0 1-.693-4.431c.196-.259.429-.486.693-.674l10.279-7.328Zm-13.507 13.86a1.128 1.128 0 0 0-1.29 0l-6.8 4.801a.963.963 0 0 0-.307 1.265l6.801 12.254c.094.17.241.316.428.414.545.286 1.204.073 1.475-.414l6.8-12.254a.963.963 0 0 0-.307-1.265l-6.8-4.801Zm-2.512-1.758a3.244 3.244 0 0 1 3.734 0l6.8 4.801a3.119 3.119 0 0 1 .944 4.071l-6.8 12.254c-.851 1.533-2.785 2.074-4.317 1.27a3.171 3.171 0 0 1-1.304-1.27l-6.801-12.254a3.12 3.12 0 0 1 .944-4.071l6.8-4.801Z"
        fill="#F88360"
      />
      <path
        d="M224.929 129.924c11.712-1.59 19.917-12.372 18.327-24.083-1.59-11.711-12.372-19.916-24.083-18.327-11.711 1.59-19.917 12.372-18.327 24.084 1.59 11.711 12.372 19.916 24.083 18.326Z"
        fill="#E0E0E0"
      />
      <path
        d="M226.898 128.021c11.698-1.685 19.814-12.535 18.128-24.233-1.685-11.697-12.535-19.814-24.233-18.128-11.697 1.686-19.814 12.535-18.128 24.233s12.535 19.814 24.233 18.128Z"
        fill="#EBEBEB"
      />
      <path
        d="M230.086 121.812c8.152-3.267 12.045-12.693 8.694-21.054-3.351-8.36-12.676-12.489-20.829-9.221-8.152 3.267-12.045 12.693-8.694 21.054 3.351 8.36 12.676 12.489 20.829 9.221Z"
        fill="#F5F5F5"
      />
      <path
        d="M209.32 112.57a16.295 16.295 0 0 1 8.636-21.01l-.438.159a16.303 16.303 0 0 0 12.132 30.26l.428-.186a16.293 16.293 0 0 1-20.758-9.223Z"
        fill="#FAFAFA"
      />
      <path
        d="M238.714 100.783a16.295 16.295 0 0 1-8.636 21.01l.433-.159a16.295 16.295 0 0 0 8.922-8.719 16.298 16.298 0 0 0-21.05-21.541l-.427.186a16.298 16.298 0 0 1 20.758 9.223Z"
        fill="#E0E0E0"
      />
      <path
        d="M222.872 116.291c2.126-.855 3.096-2.416 3.058-4.132a4.38 4.38 0 0 0 4.312.346c2.088-.839 3.108-2.598 2.74-4.998l2.582-1.03-.981-2.357-2.395.959-.548-1.348 2.4-.964-.959-2.379-2.395.959-.871-2.192-.603-1.512-2.384.959.609 1.507-11.585 4.641-.669-1.644-2.378.954.669 1.644.865 2.192-2.783 1.096.953 2.384 2.789-1.096.548 1.353-2.789 1.096.954 2.379 2.877-1.151c1.457 2.526 3.556 3.315 5.984 2.334Zm5.776-13.152 1.293 3.228c.718 1.786.378 3.102-1.14 3.71-1.518.608-2.647-.115-3.365-1.907l-1.468-3.655 4.51-1.809.17.433Zm-10.078 8.385-1.562-3.902-.115-.274 4.718-1.891 1.672 4.176c.783 1.951.482 3.288-1.151 3.946-1.633.657-2.784-.143-3.562-2.072v.017Z"
        fill="#E0E0E0"
      />
      <path
        d="M222.406 115.135c2.126-.855 3.096-2.417 3.058-4.138a4.374 4.374 0 0 0 4.313.351c2.093-.838 3.107-2.597 2.74-4.998l2.581-1.03-.954-2.384-2.4.959-.548-1.348 2.395-.964-.965-2.395-2.4.959-.866-2.192-.608-1.512-2.379.959.603 1.507-11.579 4.663-.669-1.644-2.383.954.668 1.644.872 2.192-2.79 1.096.954 2.383 2.789-1.096.548 1.354-2.789 1.096.953 2.378 2.883-1.151c1.446 2.549 3.556 3.327 5.973 2.357Zm5.781-13.152 1.294 3.222c.717 1.792.372 3.107-1.146 3.716-1.518.608-2.647-.116-3.364-1.907l-1.464-3.656 4.505-1.808.175.433Zm-10.083 8.384-1.562-3.901-.109-.274 4.713-1.891 1.676 4.176c.779 1.945.477 3.288-1.156 3.945-1.633.658-2.778-.126-3.562-2.071v.016Z"
        fill="#EBEBEB"
      />
      <path
        d="M233.596 101.583c-.137.055-.635-.833-1.282-2.247l.46.197c-.679.307-1.49.663-2.373 1.025l-.389.159-.164-.395-.258-.64a78.452 78.452 0 0 1-1.15-3.07l.46.198c-1.463.51-2.455.767-2.51.63-.055-.137.839-.641 2.247-1.288l.307-.137.153.334c.389.85.839 1.885 1.288 3.014.088.22.17.439.252.642l-.548-.236a66.392 66.392 0 0 1 2.428-.893l.35-.12.11.317c.482 1.469.74 2.455.619 2.51Zm1.644 4.252c-.219.088-.657-.487-.975-1.282-.318-.795-.4-1.512-.181-1.6.219-.088.657.488.975 1.282.318.795.395 1.529.181 1.6Zm-19.728-2.438c-.088-.219 2.384-1.414 5.518-2.669 3.135-1.255 5.749-2.099 5.837-1.88.087.22-2.384 1.414-5.519 2.675-3.134 1.26-5.748 2.093-5.836 1.874Zm9.924 4.817c-.219.087-.811-.872-1.32-2.143-.51-1.271-.74-2.367-.521-2.455.219-.088.806.871 1.315 2.137.51 1.266.746 2.373.526 2.461Zm-7.036 2.92c-.219.088-.811-.871-1.32-2.137-.51-1.266-.74-2.367-.521-2.455.219-.087.805.866 1.315 2.137.51 1.272.745 2.368.526 2.455Z"
        fill="#FAFAFA"
      />
      <path
        d="m125.739 245.46-61.119-.285a13.317 13.317 0 0 1-9.394-3.941 13.312 13.312 0 0 1-3.856-9.43l.646-153.215a13.317 13.317 0 0 1 13.377-13.256l61.113.28a13.317 13.317 0 0 1 13.251 13.37L139.11 232.2a13.314 13.314 0 0 1-13.371 13.261Z"
        fill="#455A64"
      />
      <path
        d="m127.136 70.906-11.853-.055a2.939 2.939 0 0 0-2.685 1.867c-.141.36-.21.744-.203 1.13v2.149a2.947 2.947 0 0 1-.823 2.095 2.949 2.949 0 0 1-2.065.897l-23.11-.077a2.938 2.938 0 0 1-2.86-3.02V73.74a2.94 2.94 0 0 0-2.833-3.047h-4.17l-10.368-.05a9.316 9.316 0 0 0-9.371 9.284l-.63 149.549a9.318 9.318 0 0 0 9.283 9.365l60.992.28a9.331 9.331 0 0 0 6.608-2.7 9.332 9.332 0 0 0 2.758-6.583l.63-149.55a9.334 9.334 0 0 0-2.7-6.624 9.31 9.31 0 0 0-6.6-2.757Z"
        fill="#fff"
      />
      <path
        d="M110.987 131.865c11.776 0 21.322-9.546 21.322-21.322 0-11.777-9.546-21.323-21.322-21.323s-21.323 9.546-21.323 21.323c0 11.776 9.547 21.322 21.323 21.322Z"
        fill="#E0E0E0"
      />
      <path
        d="M124.255 123.764c8.327-8.327 8.327-21.828 0-30.155-8.327-8.327-21.828-8.327-30.155 0-8.327 8.327-8.327 21.828 0 30.155 8.327 8.327 21.828 8.327 30.155 0Z"
        fill="#EBEBEB"
      />
      <path
        d="M114.385 123.408c8.443-3.038 12.887-12.175 9.924-20.408-2.962-8.233-12.209-12.444-20.652-9.406-8.444 3.038-12.887 12.176-9.925 20.408 2.963 8.233 12.209 12.444 20.653 9.406Z"
        fill="#F5F5F5"
      />
      <path
        d="M114.351 123.355a16.234 16.234 0 0 1-20.61-9.371c.05.148.093.296.143.439a16.243 16.243 0 1 0 30.567-10.993c-.049-.148-.109-.291-.164-.433a16.242 16.242 0 0 1-9.936 20.358Z"
        fill="#FAFAFA"
      />
      <path
        d="M103.671 93.659a16.244 16.244 0 0 1 20.616 9.365c-.05-.148-.094-.29-.148-.438a16.243 16.243 0 0 0-30.568 10.993c.055.148.115.29.17.433a16.24 16.24 0 0 1 9.93-20.353Z"
        fill="#E0E0E0"
      />
      <path
        d="M118.544 109.989c-.773-2.143-2.296-3.167-4.006-3.189a4.385 4.385 0 0 0 .498-4.286c-.756-2.109-2.471-3.183-4.877-2.882l-.959-2.609-2.405.866.871 2.422-1.365.494-.871-2.423-2.406.866.872 2.422-2.192.79-1.524.548.861 2.405 1.528-.548 4.209 11.695-1.688.608.866 2.406 1.688-.609 2.192-.789 1.014 2.817 2.405-.866-1.013-2.817 1.364-.487 1.014 2.816 2.406-.865-1.047-2.91c2.598-1.332 3.447-3.425 2.565-5.875Zm-12.906-6.225 3.261-1.167c1.808-.653 3.102-.269 3.655 1.265.553 1.535-.214 2.636-2.017 3.288l-3.699 1.354-1.644-4.554.444-.186Zm7.99 10.341-3.94 1.419-.28.099-1.709-4.768 4.214-1.513c1.967-.712 3.288-.361 3.891 1.288.602 1.65-.209 2.768-2.176 3.475Z"
        fill="#E0E0E0"
      />
      <path
        d="M117.376 110.411c-.772-2.143-2.296-3.167-4.006-3.195a4.341 4.341 0 0 0 .499-4.28c-.756-2.11-2.471-3.184-4.877-2.888l-.937-2.603-2.406.866.871 2.422-1.364.488-.888-2.417-2.406.866.872 2.417-2.192.789-1.53.548.867 2.406 1.528-.548 4.209 11.7-1.688.608.888 2.422 1.688-.608 2.192-.789 1.008 2.816 2.4-.871-1.013-2.817 1.397-.471 1.014 2.811 2.406-.86-1.047-2.91c2.559-1.386 3.392-3.458 2.515-5.902Zm-12.905-6.225 3.255-1.173c1.808-.647 3.107-.263 3.655 1.271.548 1.535-.208 2.631-2.017 3.288l-3.693 1.327-1.644-4.549.444-.164Zm8.017 10.346-3.94 1.414-.28.104-1.715-4.768 4.22-1.518c1.967-.707 3.288-.356 3.891 1.294.602 1.649-.236 2.756-2.176 3.474Z"
        fill="#EBEBEB"
      />
      <path
        d="M104.268 98.805c.049.137-.855.597-2.285 1.194l.214-.45a75.3 75.3 0 0 1 .937 2.396l.142.4-.4.142-.647.236a59.005 59.005 0 0 1-3.096 1.036l.214-.45c.45 1.474.674 2.472.548 2.521-.126.049-.603-.855-1.2-2.285l-.126-.307.34-.143c.86-.361 1.907-.767 3.046-1.178l.647-.23-.252.548a54.58 54.58 0 0 1-.806-2.45l-.109-.35.323-.099c1.485-.455 2.477-.696 2.51-.531Zm4.307-1.497c.082.22-.504.636-1.309.926-.806.291-1.518.34-1.601.121-.082-.22.51-.641 1.31-.926.8-.285 1.518-.345 1.6-.12Zm-3.129 19.548c-.224.082-1.326-2.423-2.466-5.59-1.139-3.167-1.885-5.798-1.644-5.88.241-.082 1.327 2.422 2.466 5.59 1.14 3.167 1.864 5.803 1.644 5.88Zm5.146-9.705c.082.219-.893.778-2.192 1.238-1.299.46-2.384.652-2.466.433-.082-.219.893-.778 2.192-1.238 1.299-.461 2.384-.658 2.466-.433Zm2.658 7.107c.082.225-.893.778-2.192 1.239-1.299.46-2.384.657-2.466.432-.082-.224.893-.778 2.192-1.238 1.299-.46 2.389-.652 2.466-.433Z"
        fill="#FAFAFA"
      />
      <path
        d="M85.718 102.931a1.228 1.228 0 0 1-1.71 1.474c-.816-.4-.931-1.518-.783-2.411.148-.894.438-1.853.055-2.675-.296-.63-.921-1.024-1.409-1.517a3.912 3.912 0 0 1-1.129-3.289 3.288 3.288 0 0 1 2.23-2.619c1.19-.345 2.658 0 3.535-.871.636-.63.663-1.644 1.146-2.406.761-1.167 2.389-1.37 3.77-1.2 1.38.17 2.784.597 4.132.246 1.123-.295 2.038-1.096 3.085-1.589a6.089 6.089 0 0 1 8.028 8.259c-.411.805-1.227 3.129-3.808 3.129"
        fill="#263238"
      />
      <path
        d="m85.186 93.27.033 1.852.373 27.74c.044 3.216 2.85 5.743 6.297 5.671 3.48-.071 6.307-2.767 6.318-6.028 0-3.414.082-7.009.082-7.009s4.384-.728 5.31-6.471c.455-2.856.313-7.535.072-11.36a6.064 6.064 0 0 0-4.27-5.415 6.066 6.066 0 0 0-2.416-.235L85.186 93.27Z"
        fill="#B78876"
      />
      <path
        d="m85.186 93.27.033 1.852.373 27.74c.044 3.216 2.85 5.743 6.297 5.671 3.48-.071 6.307-2.767 6.318-6.028 0-3.414.082-7.009.082-7.009s4.384-.728 5.31-6.471c.455-2.856.313-7.535.072-11.36a6.064 6.064 0 0 0-4.27-5.415 6.066 6.066 0 0 0-2.416-.235L85.186 93.27Z"
        fill="#FFBE9D"
      />
      <path
        d="M102.493 100.93a.729.729 0 0 1-.707.74.709.709 0 0 1-.691-.393.702.702 0 0 1-.071-.27.742.742 0 0 1 .712-.74.702.702 0 0 1 .757.663Zm.164-.822c-.093.094-.652-.317-1.442-.317-.789 0-1.375.4-1.457.301-.082-.099.05-.214.301-.4a2.05 2.05 0 0 1 1.167-.362c.412 0 .813.13 1.146.373.252.186.329.362.285.405Zm-8.089.822a.74.74 0 0 1-.712.74.7.7 0 0 1-.756-.663.73.73 0 0 1 .707-.74.706.706 0 0 1 .69.393.712.712 0 0 1 .071.27Z"
        fill="#263238"
      />
      <path
        d="M94.87 100.284c-.094.093-.653-.318-1.442-.318-.789 0-1.375.4-1.457.301-.083-.098.049-.213.301-.4a2.02 2.02 0 0 1 2.318.011c.241.159.323.335.28.406Zm2.871 5.842a5.582 5.582 0 0 1 1.288-.23c.203 0 .395-.061.428-.198.02-.209-.027-.42-.132-.603-.192-.487-.39-1.002-.603-1.539-.827-2.192-1.43-3.995-1.342-4.028.087-.033.838 1.721 1.644 3.913.202.548.389 1.057.575 1.55.127.243.164.522.104.789a.497.497 0 0 1-.334.296 1.19 1.19 0 0 1-.345.05 4.83 4.83 0 0 1-1.283 0Z"
        fill="#263238"
      />
      <path
        d="M98.29 115.502a14.617 14.617 0 0 1-7.673-2.05s1.863 3.941 7.606 3.403l.066-1.353Z"
        fill="#EB996E"
      />
      <path
        d="M95.412 106.674c.132 0 .132.854.866 1.463.734.608 1.644.515 1.644.635 0 .121-.208.17-.592.181a2.19 2.19 0 0 1-1.386-.482 1.852 1.852 0 0 1-.669-1.227c-.016-.384.077-.57.137-.57Zm-.339-9.524c-.077.213-.866.114-1.798.224s-1.682.367-1.803.175c-.055-.093.077-.296.378-.504a3.14 3.14 0 0 1 2.74-.307c.362.137.515.307.483.411Zm7.293.662c-.142.181-.685 0-1.342 0-.658 0-1.211.12-1.343-.065-.055-.094.033-.269.274-.444a1.962 1.962 0 0 1 2.192.071c.203.17.28.35.219.438Z"
        fill="#263238"
      />
      <path
        d="M103.862 95.127s-2.931 1.535-6.866-.28c-2.581-1.188-5.568-1.714-6.203-1.183-.636.532-.69 3.157-3.184 4 0 0 .329 4.993-1.644 4.878-1.973-.115-1.694-10.133-1.694-10.133l5.48-.296 5.289-1.172 5.036.548 3.912.937-.126 2.701Zm-19.755-4.159c0-.071.444-.252.992-.05.548.203.805.63.734.675-.071.043-.367-.23-.833-.395-.466-.164-.877-.131-.893-.23Zm1.655-1.677c.093 0 .06.597.165 1.271.104.674.394 1.173.323 1.222-.071.05-.504-.416-.62-1.172a1.973 1.973 0 0 1 .132-1.321Z"
        fill="#263238"
      />
      <path
        d="M86.305 100.498c-.088-.039-3.568-1.217-3.6 2.378-.034 3.595 3.578 2.893 3.583 2.789.006-.104.017-5.167.017-5.167Z"
        fill="#B78876"
      />
      <path
        d="M86.305 100.498c-.088-.039-3.568-1.217-3.6 2.378-.034 3.595 3.578 2.893 3.583 2.789.006-.104.017-5.167.017-5.167Z"
        fill="#FFBE9D"
      />
      <path
        d="M85.209 104.29s-.066.044-.17.093a.62.62 0 0 1-.471 0 1.552 1.552 0 0 1-.702-1.397 2.052 2.052 0 0 1 .186-.894.717.717 0 0 1 .477-.476.318.318 0 0 1 .367.175c.044.104 0 .175.039.181.038.005.082-.06.055-.208a.414.414 0 0 0-.143-.231.474.474 0 0 0-.345-.093.881.881 0 0 0-.658.548 2.097 2.097 0 0 0-.23.998 1.647 1.647 0 0 0 .899 1.561.662.662 0 0 0 .548-.087c.137-.082.159-.165.148-.17Z"
        fill="#EB996E"
      />
      <path
        d="M101.841 89.214a.917.917 0 0 1 .361-.164c.381-.09.781-.042 1.129.137.259.119.48.309.636.548.173.297.237.646.181.986-.065.39-.258.746-.548 1.014a2.352 2.352 0 0 1-1.151.58 3.364 3.364 0 0 1-2.779-.706 4 4 0 0 1-1.068-1.244 2.302 2.302 0 0 1-.247-1.732 2.232 2.232 0 0 1 1.26-1.337 3.916 3.916 0 0 1 1.88-.329 4.668 4.668 0 0 1 3.409 1.918c.408.559.645 1.223.685 1.913.03.67-.113 1.336-.417 1.934a4.176 4.176 0 0 1-1.222 1.463 4.867 4.867 0 0 1-1.644.79 6.997 6.997 0 0 1-3.326.065c-1.008-.323-1.858-.767-2.685-1.052a10.925 10.925 0 0 0-2.264-.592 7.839 7.839 0 0 0-1.77-.038c-.964.11-1.474.334-1.485.29a2.74 2.74 0 0 1 .368-.142c.356-.12.723-.206 1.096-.258a7.199 7.199 0 0 1 1.797 0c.79.099 1.567.283 2.318.548.833.28 1.71.718 2.674 1.014a6.818 6.818 0 0 0 3.195-.087 4.19 4.19 0 0 0 2.685-2.127 3.41 3.41 0 0 0-.257-3.556 4.38 4.38 0 0 0-3.19-1.803 3.676 3.676 0 0 0-1.748.296 1.966 1.966 0 0 0-1.123 1.162 2.066 2.066 0 0 0 .214 1.54c.246.456.583.857.991 1.178a3.171 3.171 0 0 0 2.598.696c.395-.072.763-.25 1.063-.516.265-.238.442-.558.504-.91.059-.304.01-.62-.137-.892a1.464 1.464 0 0 0-.548-.521 1.644 1.644 0 0 0-1.063-.18c-.126.03-.25.07-.372.114Z"
        fill="#455A64"
      />
      <path
        d="m118.023 239.103-4.39-58.652-36.25-1.102v59.53l19.619.087.646-44.443 2.559 44.46 17.816.12Z"
        fill="#263238"
      />
      <path
        d="M99.232 180.467c.11.705.162 1.418.153 2.132.055 1.321.099 3.146.126 5.157 0 1.003 0 1.956.028 2.844a5.633 5.633 0 0 1-.39 2.329 2.597 2.597 0 0 1-1.057 1.2.976.976 0 0 1-.548.175c0-.033.197-.082.493-.29a2.68 2.68 0 0 0 .899-1.184 5.714 5.714 0 0 0 .29-2.23c0-.882-.033-1.836-.055-2.839 0-2.011-.032-3.836 0-5.157a12.932 12.932 0 0 1 .06-2.137Zm-2.85 1.403c.033.039-.247.208-.258.636a.695.695 0 0 0 .269.592.651.651 0 0 0 .75-.066.554.554 0 0 0 .28-.455.553.553 0 0 0-.24-.477.79.79 0 0 0-.752-.18v-.137c.148.076.23.131.22.153-.011.022-.105 0-.274 0l-.148-.033.137-.11a.892.892 0 0 1 1.013.083.94.94 0 0 1 .378.674.898.898 0 0 1-.4.783.964.964 0 0 1-1.15.028.931.931 0 0 1-.324-.877.867.867 0 0 1 .296-.548c.11-.082.192-.088.203-.066Z"
        fill="#455A64"
      />
      <path
        d="M147.456 171.058v-.033c2.674.176 5.365.384 6.993.483 2.701.164 3.496-.094 3.507-.499.027-.877-5.738-.784-5.288-1.425 0 0 5.145 1.173 5.315.499.34-1.376-5.403-1.918-5.403-1.918 3.573 1.32 5.628 1.244 5.546.323-.061-.614-5.546-1.863-5.546-1.863s4.209 1.189 5.009 1.162c.8-.028.953-.724.416-1.359-.537-.636-7.091-3.031-8.121-3.398-1.03-.367-.461-1.529-.461-1.529 1.02-2.192 1.579-3.781 1.343-4.384a.708.708 0 0 0-1.326.208 8.656 8.656 0 0 1-1.096 2.066 5.55 5.55 0 0 1-1.266 1.359c-.575.439-1.096.784-1.518 1.069l-4.028.701.943 7.272 4.981 1.266Z"
        fill="#FFBE9D"
      />
      <path
        d="M149.631 168.581c-.043.028-.235-.241-.624-.592a4.393 4.393 0 0 0-1.77-.964l-.384-.104.334-.225.247-.164a5.165 5.165 0 0 0 1.49-1.644c.285-.504.401-.861.455-.844.055.016 0 .394-.224.959a4.52 4.52 0 0 1-1.513 1.841c-.088.066-.181.121-.268.175l-.044-.328a3.88 3.88 0 0 1 1.852 1.145c.362.4.482.723.449.745Z"
        fill="#A36957"
      />
      <path
        d="M157.221 167.869c-.044.093-1.178-.313-2.592-.746-1.414-.432-2.581-.739-2.564-.843.016-.105 1.233.054 2.668.498 1.436.444 2.527 1.003 2.488 1.091Zm-.055 1.266c-.033.098-1.063-.187-2.345-.488-1.282-.301-2.324-.548-2.313-.63.011-.082 1.096-.039 2.401.274 1.304.312 2.29.756 2.257.844Z"
        fill="#EB996E"
      />
      <path
        d="M90.864 121.552a1.74 1.74 0 0 0-1.222.159 2.05 2.05 0 0 0-.866 1.008 4.988 4.988 0 0 0-.274.778l-.115.433a1.093 1.093 0 0 1-.23.504.504.504 0 0 1-.323.154.736.736 0 0 1-.307-.061c-.16-.065-.28-.131-.417-.197l-.767-.378-1.293-.658a4.793 4.793 0 0 1-1.151-.69c.438.097.862.252 1.26.46l1.326.586.779.362.4.181c.126.049.175.044.23 0a2.95 2.95 0 0 0 .263-.784c.08-.283.187-.558.318-.822a2.196 2.196 0 0 1 1.052-1.096 1.488 1.488 0 0 1 1.014-.12c.235.066.334.164.323.181Zm7.666.729c.06.076-1.183 1.096-2.828 2.35l-.235.176-.05-.291c0-.115-.043-.241-.065-.367a4.308 4.308 0 0 0-1.063-2.499 3.92 3.92 0 0 0-.965-.613c0-.039.548-.077 1.135.422.351.296.627.672.805 1.096.213.496.362 1.017.444 1.55l.066.373-.29-.115c1.66-1.255 2.991-2.159 3.046-2.082Z"
        fill="#263238"
      />
      <path
        d="m142.94 161.32-1.501-.055c-2.126.252-8.22-.378-13.015-1.501-4.795-1.124-4.625-1.814-5.245-2.691-.619-.877-1.693-5.639-1.693-5.639a125.84 125.84 0 0 0-3.891-15.815c-2.608-8.105-8.62-10.127-8.62-10.127a5.079 5.079 0 0 0-.827-.356c-.477-.274-7.212-2.428-9.71-2.565v-4.713c-4.198.411-6.363 5.371-6.363 5.371-1.737-4.198-8.264-4.806-8.264-4.806l.12 4.334a30.686 30.686 0 0 0-6.844 1.25c-3.639 1.315-5.348 2.592-8.368 4.976-3.02 2.383-3.907 7.507-3.907 11.853l13.305 5.178v.329l-.153 15.426-.592 17.58 36.338 1.096-1.841-14.949c1.019 1.792 4.63 4.219 11.864 6.055 8.132 2.066 20.21.351 20.21.351l-1.003-10.582Z"
        fill="#F88360"
      />
      <path
        d="M95.226 132.068c0-.038.274-.17.614.12a.837.837 0 0 1 .274.686.841.841 0 0 1-.64.663.835.835 0 0 1-.862-.324.821.821 0 0 1-.049-.734c.18-.406.51-.422.499-.373-.011.05-.187.148-.252.455a.59.59 0 0 0 .082.471.548.548 0 0 0 .893-.208.599.599 0 0 0-.132-.46c-.191-.258-.427-.252-.427-.296Zm1.337 12.922c0-.039.274-.17.608.12a.816.816 0 0 1 .28.685.84.84 0 0 1-1.502.346.82.82 0 0 1-.05-.74c.182-.406.51-.422.5-.373-.011.049-.192.148-.258.455a.614.614 0 0 0 .088.471.538.538 0 0 0 .51.163.553.553 0 0 0 .405-.349.617.617 0 0 0-.132-.46c-.213-.269-.449-.263-.449-.318Zm.822 12.626c-.027-.039.274-.17.614.12a.822.822 0 0 1 .274.685.84.84 0 0 1-1.119.631.843.843 0 0 1-.382-.291.821.821 0 0 1-.05-.734c.181-.406.51-.422.499-.378-.011.044-.187.153-.252.455a.577.577 0 0 0 .082.471.478.478 0 0 0 .504.153.483.483 0 0 0 .39-.356.59.59 0 0 0-.132-.46c-.192-.247-.428-.241-.428-.296Zm.855 13.738c0-.038.28-.17.614.121a.815.815 0 0 1 .28.685.797.797 0 0 1-.642.663.794.794 0 0 1-.86-.324.802.802 0 0 1-.05-.734c.176-.405.505-.422.5-.373-.006.05-.193.148-.258.455a.633.633 0 0 0 .082.472.507.507 0 0 0 .51.153.48.48 0 0 0 .383-.362.616.616 0 0 0-.126-.46c-.22-.247-.433-.241-.433-.296Z"
        fill="#263238"
      />
      <path
        opacity={0.3}
        d="m78.852 140.337 3.266 39.171h-4.735l.51-16.166.203-17.333.756-5.672Z"
        fill="#000"
      />
      <path
        d="M113.25 175.387a1.57 1.57 0 0 1-.088-.351c-.049-.274-.109-.613-.186-1.024-.159-.894-.362-2.192-.597-3.792-.477-3.201-1.058-7.634-1.644-12.539-.587-4.904-1.162-9.316-1.644-12.538-.231-1.556-.422-2.839-.548-3.787a48.024 48.024 0 0 0-.148-1.035 1.633 1.633 0 0 1-.028-.362c.054.11.089.229.104.351.055.274.126.608.214 1.019.175.888.417 2.192.674 3.776.548 3.195 1.134 7.628 1.737 12.532.603 4.905 1.151 9.289 1.551 12.55.197 1.567.362 2.849.482 3.803.05.416.088.756.121 1.036.018.119.018.241 0 .361Z"
        fill="#263238"
      />
      <path
        opacity={0.3}
        d="M109.134 143.51c2.088 7.195 2.773 13.514 6.697 19.454a18.347 18.347 0 0 0 5.441 5.348c4.226 2.691 9.316 3.579 14.331 4.045h3.375s-7.589.646-13.727-.483c0 0-9.442-1.682-13.152-6.028l-2.965-22.336Z"
        fill="#000"
      />
      <path
        d="M96.087 178.209a3.211 3.211 0 0 1-.055-.548c0-.4-.05-.937-.088-1.605-.071-1.431-.17-3.436-.296-5.902a814.219 814.219 0 0 0-1.26-19.454c-.581-7.59-1.19-14.462-1.644-19.432-.214-2.461-.39-4.461-.515-5.891-.05-.669-.094-1.2-.12-1.6a3.299 3.299 0 0 1 0-.548c.049.178.08.362.092.548.05.394.11.926.192 1.594.153 1.425.367 3.425.625 5.875a795.444 795.444 0 0 1 1.737 19.432c.58 7.601.964 14.478 1.156 19.47.093 2.494.154 4.51.181 5.908v1.605c.012.183.01.366-.005.548Z"
        fill="#263238"
      />
      <path
        d="M131.553 190.775c12.678 0 22.956-10.052 22.956-22.451 0-12.4-10.278-22.452-22.956-22.452-12.678 0-22.956 10.052-22.956 22.452 0 12.399 10.278 22.451 22.956 22.451Z"
        fill="#F88360"
      />
      <path
        opacity={0.4}
        d="M131.553 190.775c12.678 0 22.956-10.052 22.956-22.451 0-12.4-10.278-22.452-22.956-22.452-12.678 0-22.956 10.052-22.956 22.452 0 12.399 10.278 22.451 22.956 22.451Z"
        fill="#000"
      />
      <path
        d="M130.155 188.419c12.679 0 22.956-10.052 22.956-22.452 0-12.399-10.277-22.451-22.956-22.451-12.678 0-22.955 10.052-22.955 22.451 0 12.4 10.277 22.452 22.955 22.452Z"
        fill="#F88360"
      />
      <path
        opacity={0.2}
        d="M130.892 182.409c9.647-.497 17.084-8.361 16.61-17.565-.474-9.204-8.68-16.261-18.328-15.764-9.648.497-17.084 8.361-16.61 17.564.474 9.204 8.68 16.262 18.328 15.765Z"
        fill="#fff"
      />
      <path
        d="M131.668 182.276c-9.442.898-17.86-5.732-19.032-14.884 0 .164 0 .323.033.488.937 9.398 9.485 16.281 19.097 15.344 9.612-.937 16.643-9.272 15.712-18.676 0-.165-.039-.323-.061-.482.652 9.222-6.296 17.322-15.749 18.21Z"
        fill="#FAFAFA"
      />
      <path
        opacity={0.3}
        d="M128.38 149.204c9.447-.899 17.859 5.732 19.037 14.884 0-.165 0-.324-.038-.488-.932-9.398-9.48-16.281-19.092-15.344-9.612.937-16.649 9.277-15.712 18.676 0 .164.039.323.061.482-.641-9.223 6.307-17.311 15.744-18.21Z"
        fill="#000"
      />
      <path
        d="M139.587 169.721c-.241-2.389-1.557-3.836-3.338-4.296a4.532 4.532 0 0 0 1.644-4.242c-.23-2.351-1.737-3.891-4.323-4.203l-.291-2.905-2.74.263.269 2.697-1.557.148-.268-2.697-2.74.258.263 2.696-2.493.241-1.738.165.269 2.679 1.737-.169 1.293 13.031-1.923.181.268 2.68 1.924-.181 2.493-.241.313 3.14 2.74-.263-.313-3.135 1.557-.148.306 3.135 2.74-.258-.317-3.239c3.063-.718 4.493-2.614 4.225-5.337Zm-11.821-9.705 3.71-.351c2.061-.197 3.288.548 3.48 2.241.192 1.694-.915 2.631-2.976 2.828l-4.208.4-.504-5.069.498-.049Zm5.601 12.604-4.488.427-.313.028-.548-5.305 4.806-.455c2.242-.214 3.535.482 3.716 2.324.181 1.841-.932 2.794-3.173 3.008v-.027Z"
        fill="#F88360"
      />
      <path
        opacity={0.3}
        d="M139.587 169.721c-.241-2.389-1.557-3.836-3.338-4.296a4.532 4.532 0 0 0 1.644-4.242c-.23-2.351-1.737-3.891-4.323-4.203l-.291-2.905-2.74.263.269 2.697-1.557.148-.268-2.697-2.74.258.263 2.696-2.493.241-1.738.165.269 2.679 1.737-.169 1.293 13.031-1.923.181.268 2.68 1.924-.181 2.493-.241.313 3.14 2.74-.263-.313-3.135 1.557-.148.306 3.135 2.74-.258-.317-3.239c3.063-.718 4.493-2.614 4.225-5.337Zm-11.821-9.705 3.71-.351c2.061-.197 3.288.548 3.48 2.241.192 1.694-.915 2.631-2.976 2.828l-4.208.4-.504-5.069.498-.049Zm5.601 12.604-4.488.427-.313.028-.548-5.305 4.806-.455c2.242-.214 3.535.482 3.716 2.324.181 1.841-.932 2.794-3.173 3.008v-.027Z"
        fill="#000"
      />
      <path
        d="M138.244 169.847c-.236-2.389-1.556-3.836-3.337-4.296a4.529 4.529 0 0 0 1.644-4.242c-.231-2.351-1.738-3.891-4.324-4.203l-.291-2.904-2.74.263.269 2.696-1.556.148-.263-2.696-2.74.257.268 2.696-2.499.241-1.737.165.269 2.68 1.737-.17 1.293 13.031-1.885.203.263 2.68 1.923-.181 2.494-.236.312 3.135 2.74-.263-.312-3.135 1.556-.148.312 3.135 2.74-.258-.323-3.239c3.036-.739 4.466-2.635 4.187-5.359Zm-11.815-9.694 3.704-.351c2.061-.197 3.288.548 3.48 2.242.192 1.693-.915 2.63-2.975 2.827l-4.209.4-.504-5.069.504-.049Zm5.595 12.604-4.488.427-.312.028-.548-5.305 4.806-.455c2.241-.213 3.534.483 3.715 2.324.181 1.841-.921 2.784-3.162 2.997l-.011-.016Z"
        fill="#F88360"
      />
      <path
        d="M150.908 156.3s-.038-.049-.104-.153-.159-.268-.274-.466l-.433-.761a9.31 9.31 0 0 0-.635-1.014l-.866-1.222c-.313-.45-.735-.872-1.14-1.365a15.024 15.024 0 0 0-1.436-1.452c-.268-.247-.515-.548-.822-.756l-.926-.718a23.753 23.753 0 0 0-4.494-2.691l-1.342-.548c-.225-.088-.45-.186-.685-.268l-.718-.209c-.482-.137-.97-.296-1.469-.416l-1.545-.296a13.797 13.797 0 0 0-1.595-.203 12.722 12.722 0 0 0-1.644-.104h-1.682l-1.704.159a24.185 24.185 0 0 0-6.85 1.951 24.802 24.802 0 0 0-5.941 3.913 24.081 24.081 0 0 0-4.181 5.145l-.745 1.354-.181.34-.148.35-.296.702a23.032 23.032 0 0 0-.959 2.74c-.137.449-.203.91-.307 1.353-.102.436-.18.877-.235 1.321-.118.83-.184 1.666-.198 2.504-.031.765-.009 1.53.066 2.291.037.681.123 1.359.258 2.028.131.619.208 1.216.361 1.737.154.52.302 1.003.428 1.436.112.374.249.74.411 1.096.131.317.241.586.328.811.088.224.148.378.203.504a.626.626 0 0 1 .055.175 1.672 1.672 0 0 1-.082-.164c-.06-.121-.132-.285-.225-.488l-.356-.806a7.521 7.521 0 0 1-.438-1.096l-.455-1.435c-.165-.548-.247-1.124-.389-1.748a14.919 14.919 0 0 1-.28-2.039 16.613 16.613 0 0 1-.088-2.307c.008-.849.069-1.696.181-2.537.049-.434.124-.864.225-1.288.099-.449.159-.915.296-1.376.258-.949.578-1.881.959-2.789.093-.236.192-.471.29-.712l.154-.356.181-.346c.246-.46.498-.926.75-1.403a24.294 24.294 0 0 1 4.215-5.217 24.928 24.928 0 0 1 6.028-3.962 24.252 24.252 0 0 1 6.932-1.923l1.726-.159h1.704c.55.003 1.099.042 1.644.115.541.037 1.079.104 1.611.203l1.557.307c.509.126.997.29 1.485.427l.729.214c.235.088.46.186.69.274l1.348.548a24.129 24.129 0 0 1 4.521 2.74l.932.74c.307.235.548.548.822.761.508.462.984.958 1.425 1.485.405.499.827.932 1.134 1.387l.849 1.244c.23.328.435.672.614 1.03.159.307.301.548.411.778l.252.483a.867.867 0 0 1 .093.147Z"
        fill="#FAFAFA"
      />
      <path
        d="M127.668 154.613a3.566 3.566 0 0 1-.746.137 86.2 86.2 0 0 1-1.983.241l.104-.132c.088.8.186 1.715.29 2.696v.143h-.142l-.74.071-3.496.307.104-.126c.071.811.12 1.48.148 1.951a3.52 3.52 0 0 1 0 .74 2.796 2.796 0 0 1-.143-.729 89.415 89.415 0 0 1-.241-1.94v-.115h.121l3.491-.356.739-.066-.126.153c-.087-.981-.17-1.896-.246-2.701v-.121h.12a93.704 93.704 0 0 1 1.995-.142c.249-.035.501-.039.751-.011Zm4.871-.417a4.904 4.904 0 0 1-1.644.296c-.543.094-1.1.094-1.644 0a9.882 9.882 0 0 1 3.288-.312v.016Zm-8.439 19.142c-.082 0-.427-2.844-.778-6.373-.351-3.529-.548-6.39-.493-6.401.055-.011.427 2.844.778 6.373.351 3.529.57 6.395.493 6.401Zm7.941-8.576c-.86.228-1.742.36-2.631.394-.879.129-1.77.166-2.658.11a12.523 12.523 0 0 1 2.631-.395c.88-.129 1.77-.165 2.658-.109Zm.893 7.945c-.861.225-1.742.357-2.631.395-.879.129-1.77.166-2.657.11.86-.225 1.742-.357 2.63-.395.88-.129 1.77-.165 2.658-.11Z"
        fill="#FAFAFA"
      />
      <path
        d="M126.873 190.309c-.066.039-.427-.454-.805-1.096a3.43 3.43 0 0 1-.548-1.244c.071-.038.432.455.811 1.096.265.373.45.796.542 1.244Zm-3.776-1.183c-.071.044-.416-.428-.783-1.052a3.126 3.126 0 0 1-.548-1.201c.071-.038.416.433.778 1.058.268.354.457.761.553 1.195Zm-3.485-1.617c-.066.038-.417-.433-.778-1.058a3.05 3.05 0 0 1-.548-1.2c.071-.038.416.433.783 1.058.265.356.45.766.543 1.2Zm11.256 3.277c-.071.038-.433-.455-.811-1.096a3.28 3.28 0 0 1-.548-1.244c.066-.038.427.455.805 1.096.265.374.453.797.554 1.244Zm3.699-.203c-.066.039-.427-.454-.806-1.096-.264-.375-.45-.8-.548-1.249.072-.038.433.455.811 1.096.266.374.451.8.543 1.249Zm3.639-.772c-.066.038-.428-.455-.806-1.096a3.43 3.43 0 0 1-.548-1.244c.071-.044.433.455.811 1.096.267.371.452.795.543 1.244Zm3.326-1.195c-.071.038-.433-.455-.811-1.096a3.28 3.28 0 0 1-.548-1.249c.066-.039.427.454.805 1.096.266.376.454.8.554 1.249Zm3.184-1.825c-.066.039-.428-.455-.806-1.096a3.396 3.396 0 0 1-.548-1.249c.066-.039.428.455.811 1.096.266.374.451.799.543 1.249Zm2.762-2.236c-.066.044-.427-.455-.805-1.096a3.38 3.38 0 0 1-.548-1.249c.071-.039.432.455.811 1.096.266.374.45.8.542 1.249Zm2.093-2.499c-.071.039-.411-.416-.761-1.024-.351-.609-.587-1.124-.516-1.162.072-.038.406.416.762 1.019.252.348.428.746.515 1.167Zm1.99-2.712c-.072.038-.411-.422-.762-1.03a2.98 2.98 0 0 1-.521-1.167c.072-.039.411.421.768 1.024.253.35.429.75.515 1.173Zm1.528-3.228c-.071.044-.389-.383-.723-.953-.334-.57-.548-1.058-.471-1.096.076-.039.389.389.717.953.243.323.406.699.477 1.096Zm1.003-3.496c-.066.038-.383-.373-.701-.926-.318-.554-.548-1.03-.461-1.096.088-.066.384.378.707.931.324.554.526 1.042.455 1.091Zm.422-3.781c-.071.044-.438-.466-.827-1.129a3.62 3.62 0 0 1-.576-1.272c.347.318.626.702.822 1.129.276.382.473.814.581 1.272Zm-.422-4.987a4.048 4.048 0 0 1-.871-1.211 4.004 4.004 0 0 1-.625-1.359c.364.347.66.76.872 1.216.294.406.507.866.624 1.354Z"
        fill="#263238"
      />
      <path
        d="M102.399 178.193c2.494.592 12.029 1.523 13.58 1.677 3.228.323 3.26.033 3.233-.345-.071-1.031-5.655-.784-4.483-1.529 1.173-.746 6.861.487 6.741-.581-.154-1.392-5.995-1.025-6.489-1.036-.493-.011-.509-.723-.06-.69.647.038 7.42.312 7.283-1.058-.077-.762-1.43-.499-1.43-.499s-6.357.214-6.401-.433c-.044-.646 3.683-.909 4.932-.904 1.25.006 1.759-.592 1.304-1.145-.312-.378-4.181-.482-5.43-.455-1.25.027-4.675.153-3.042-1.288s2.357-3.156 2.225-3.759a.679.679 0 0 0-1.288 0 7.78 7.78 0 0 1-1.348 1.732 5.098 5.098 0 0 1-1.408 1.057l-.701.346c-1.042.487-5.3 1.002-6.439.871l-.779 8.039Z"
        fill="#FFBE9D"
      />
      <path
        d="M64.812 140.836v22.523c0 6.499 12.96 12.187 20.183 13.415l19.542 2.482.646-11.371s-24.232-6.357-25.526-7.957c-1.293-1.6-.707-22.407-.707-22.407l-14.138 3.315Z"
        fill="#F88360"
      />
      <path
        d="M77.158 159.106c.103.026.204.061.302.104l.87.335 3.289 1.309c1.178.488 2.652.915 4.302 1.42l5.518 1.611a959.144 959.144 0 0 0 13.749 3.836l.11.033v.115c-.082 1.54-.175 3.162-.269 4.806-.126 2.247-.252 4.444-.372 6.576v.137h-.132a91.99 91.99 0 0 1-19.536-2.576 92.985 92.985 0 0 1-5.6-1.54c-.647-.197-1.146-.361-1.486-.471l-.383-.132-.126-.054.137.027.389.11 1.496.427c1.31.373 3.217.899 5.617 1.458a95.294 95.294 0 0 0 19.498 2.471l-.143.132c.121-2.116.241-4.313.367-6.576l.274-4.806.105.142c-5.047-1.403-9.722-2.74-13.739-3.902-2.01-.591-3.836-1.145-5.513-1.644-1.677-.498-3.107-.959-4.307-1.474l-3.288-1.375-.86-.367a1.688 1.688 0 0 1-.269-.132Z"
        fill="#263238"
      />
      <path
        d="M80.156 160.515a8.853 8.853 0 0 1-1.387-1.754 8.815 8.815 0 0 1-1.15-1.912 8.83 8.83 0 0 1 1.386 1.753c.46.589.846 1.231 1.15 1.913Z"
        fill="#263238"
      />
      <path
        opacity={0.3}
        d="M83.899 122.604s4.422 4.559 5.036 3.885c.614-.674.888-5.058 2.685-4.186a1.704 1.704 0 0 0-1.096-.795c-.685-.077-1.792-.181-2.34 2.329 0 0 0 .778-.586.548-.586-.23-3.7-1.781-3.7-1.781Zm8.861-.614a1.22 1.22 0 0 1 1.161.965c.177 1.192.276 2.395.296 3.6l3.924-3.874-2.57 1.792s-.35-3.047-2.252-3.436l-.56.953Z"
        fill="#000"
      />
      <path
        d="M104.745 175.245h-.192l-.548-.088-2.093-.361-7.672-1.403c-3.245-.603-7.124-1.326-11.388-2.159l-3.288-.658-1.693-.356c-.285-.055-.548-.132-.86-.203-.313-.071-.549-.175-.855-.285a8.928 8.928 0 0 1-3.064-2.044 9.398 9.398 0 0 1-2.055-3.146 9.736 9.736 0 0 1-.68-3.699v-10.466c0-4.357 0-8.286.034-11.591.032-3.304.032-5.951.043-7.814v-2.674a1.309 1.309 0 0 1 0-.192.88.88 0 0 1 0 .192v2.674c0 1.863 0 4.526.05 7.814.05 3.288 0 7.234.027 11.591v10.466a9.466 9.466 0 0 0 .658 3.59 9.168 9.168 0 0 0 1.989 3.052 8.712 8.712 0 0 0 2.96 1.94c.268.11.547.192.821.28.274.087.548.142.85.202l1.687.357 3.288.668 11.503 2.236 7.672 1.491 2.082.416.548.115c.061.012.119.03.176.055Z"
        fill="#FAFAFA"
      />
      <path
        d="M88.14 162.668a2.364 2.364 0 0 1-.07-.422c-.033-.307-.077-.718-.138-1.227-.11-1.096-.263-2.62-.454-4.499-.357-3.836-.85-9.075-1.392-14.868-.28-2.899-.488-5.677-.762-8.181-.274-2.505-.943-4.719-2.066-6.253a7.2 7.2 0 0 0-3.496-2.674 7.344 7.344 0 0 0-1.644-.291c.144-.014.289-.014.433 0a4.92 4.92 0 0 1 1.222.203 7.129 7.129 0 0 1 3.622 2.663c1.184 1.557 1.863 3.836 2.192 6.335.329 2.499.51 5.283.784 8.187.515 5.798.975 11.043 1.315 14.873.153 1.885.274 3.42.361 4.516.039.509.066.92.088 1.227.014.137.016.274.006.411Zm17.86 7.634a2.769 2.769 0 0 1-.05-.471c0-.335-.055-.79-.093-1.359-.071-1.206-.181-2.905-.312-4.993a481.08 481.08 0 0 0-1.354-16.44 474.241 474.241 0 0 0-1.934-16.39l-.68-4.932c-.076-.563-.137-1.012-.181-1.349a3.714 3.714 0 0 1-.043-.471c.048.15.083.304.104.461.054.334.131.778.23 1.342.197 1.167.466 2.855.772 4.932a372.307 372.307 0 0 1 2.017 16.396 356.37 356.37 0 0 1 1.271 16.468c.11 2.109.187 3.836.225 4.997 0 .548 0 1.025.033 1.36.01.149.008.299-.005.449Zm8.515-21.279a5.738 5.738 0 0 1-.164-.871c-.093-.614-.219-1.42-.367-2.39-.301-2.016-.74-4.8-1.288-7.863a261.032 261.032 0 0 0-1.518-7.82c-.197-.965-.362-1.759-.488-2.368a4.812 4.812 0 0 1-.148-.877c.107.276.191.56.253.85.148.548.345 1.353.58 2.351a167.05 167.05 0 0 1 1.601 7.814c.553 3.069.953 5.858 1.211 7.886.126 1.014.219 1.836.274 2.4.045.294.063.591.054.888Z"
        fill="#FAFAFA"
      />
      <path
        d="M117.069 135.646h-.531l-1.507-.033-5.541-.12a646.027 646.027 0 0 0-18.292-.126c-7.14.055-13.612.23-18.292.4l-5.54.208-1.507.049h-.395a.324.324 0 0 1-.132 0h.132l.39-.038 1.506-.104a367.15 367.15 0 0 1 5.535-.296 483.04 483.04 0 0 1 18.298-.482c7.146-.055 13.617.06 18.303.208 2.34.071 4.23.148 5.54.214l1.507.082.389.027.137.011Zm-28.666-12.724a12.642 12.642 0 0 1-2.29-1.321 12.202 12.202 0 0 1-2.137-1.551 11.96 11.96 0 0 1 2.296 1.315 11.67 11.67 0 0 1 2.131 1.557Zm10.051-2.872a7.88 7.88 0 0 1-1.562 1.458c-.46.367-.888.685-1.211.904a2.976 2.976 0 0 1-.4.23.273.273 0 0 1-.126.044c-.06 0-.05-.049-.033-.033.506-.483 1.037-.94 1.589-1.37.524-.485 1.11-.9 1.743-1.233ZM78.6 155.04c0 .082-2.96.301-6.62.493s-6.636.28-6.636.203c0-.077 2.959-.296 6.62-.488 3.66-.192 6.636-.285 6.636-.208Zm30.485 1.617c0 .076-6.609.356-14.768.624-8.16.269-14.796.417-14.796.34 0-.077 6.608-.361 14.796-.625 8.187-.263 14.763-.416 14.768-.339Zm-30.573 17.771c.22-.616.472-1.219.756-1.808a72.908 72.908 0 0 0 1.749-4.34 77.312 77.312 0 0 0 1.468-4.439 15.59 15.59 0 0 1 .587-1.852c-.004.175-.028.35-.072.52-.065.329-.175.8-.317 1.387a49.628 49.628 0 0 1-1.398 4.482 46.408 46.408 0 0 1-1.83 4.324c-.263.548-.488.97-.652 1.266a1.83 1.83 0 0 1-.29.46Zm16.478 3.984a9.99 9.99 0 0 1 .044-1.83 49.7 49.7 0 0 1 .515-4.384c.274-1.699.608-3.233.893-4.329a9.57 9.57 0 0 1 .548-1.748 15.073 15.073 0 0 1-.334 1.797 81.323 81.323 0 0 0-.81 4.329 110.832 110.832 0 0 0-.593 4.357c-.05.608-.138 1.211-.263 1.808Z"
        fill="#FAFAFA"
      />
      <path
        d="M78.835 142.968a3.88 3.88 0 0 1-.126-.789c-.06-.515-.11-1.261-.148-2.192-.038-.932-.05-2.017-.131-3.223a16.825 16.825 0 0 0-.663-3.836 8.428 8.428 0 0 0-1.89-3.353 6.375 6.375 0 0 0-2.659-1.644 5.084 5.084 0 0 0-2.115-.203c-.504.066-.773.17-.784.137a.75.75 0 0 1 .192-.082c.178-.071.361-.126.548-.165a4.837 4.837 0 0 1 2.192.121 6.4 6.4 0 0 1 2.795 1.644 8.572 8.572 0 0 1 1.973 3.458c.379 1.274.598 2.59.652 3.918.066 1.222.06 2.312.071 3.233.011.921.027 1.644.055 2.192.034.26.046.522.038.784Z"
        fill="#263238"
      />
    </svg>
  );
};
export default SvgCryptoImage;
