import * as React from 'react';
import { SVGProps } from 'react';

const SvgStarDollarInvertedIcon = (props: SVGProps) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m17.736 6.897-4.959-.721-2.216-4.494a.628.628 0 0 0-1.121 0L7.223 6.176l-4.96.72a.624.624 0 0 0-.345 1.067l3.588 3.498-.848 4.94a.624.624 0 0 0 .907.658L10 14.727l4.436 2.332a.624.624 0 0 0 .906-.658l-.848-4.94 3.588-3.498a.623.623 0 0 0 .182-.357.623.623 0 0 0-.528-.71Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.874 10c-.084-.156-.2-.29-.35-.402a2.323 2.323 0 0 0-.533-.29 8.689 8.689 0 0 0-.675-.236 8.54 8.54 0 0 1-.333-.107 1.409 1.409 0 0 1-.24-.105.474.474 0 0 1-.145-.121.254.254 0 0 1-.048-.156c0-.059.015-.11.044-.15a.327.327 0 0 1 .121-.102.636.636 0 0 1 .176-.056c.067-.011.138-.017.214-.017.074 0 .146.01.218.03a1.312 1.312 0 0 1 .384.18c.057.039.108.076.154.112l.031.026c.032.026.062.047.088.064a.16.16 0 0 0 .088.026c.04 0 .079-.019.117-.055l.528-.52a.508.508 0 0 0 .068-.08.14.14 0 0 0 .022-.077c0-.028-.015-.06-.046-.1a.938.938 0 0 0-.126-.122 1.929 1.929 0 0 0-.185-.133 4.022 4.022 0 0 0-.223-.13 2.305 2.305 0 0 0-.567-.21c-.059-.013-.119-.02-.178-.03v-.432a.118.118 0 0 0-.057-.102.198.198 0 0 0-.109-.038h-.649c-.05 0-.088.014-.113.041a.14.14 0 0 0-.038.1v.448c-.072.013-.147.023-.214.042a1.82 1.82 0 0 0-.579.267 1.21 1.21 0 0 0-.368.415c-.087.162-.13.342-.13.54 0 .185.035.354.103.508.07.153.173.293.31.42.14.127.312.242.519.345.207.103.448.2.724.29.063.018.136.042.222.071.085.029.166.063.245.104.078.04.144.086.198.139a.241.241 0 0 1 .081.177c0 .06-.01.116-.03.17a.346.346 0 0 1-.098.14.508.508 0 0 1-.188.097 1 1 0 0 1-.295.037c-.127 0-.248-.02-.364-.06a1.714 1.714 0 0 1-.347-.17 2.066 2.066 0 0 1-.209-.148 4.86 4.86 0 0 0-.15-.12.65.65 0 0 0-.083-.058.137.137 0 0 0-.065-.019c-.03 0-.07.016-.118.048l-.597.527c-.052.045-.079.085-.079.121 0 .035.027.077.08.129l.013.012.014.013.01.01c.11.112.238.218.382.316a2.926 2.926 0 0 0 .966.429c.015.004.031.005.047.009v.47c0 .093.05.14.151.14h.65c.11 0 .165-.047.165-.14v-.46a2.074 2.074 0 0 0 .941-.378 1.49 1.49 0 0 0 .426-.487c.103-.19.155-.403.155-.637 0-.222-.042-.41-.126-.566Z"
        fill="#F88360"
      />
    </svg>
  );
};
export default SvgStarDollarInvertedIcon;
