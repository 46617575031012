import * as React from 'react';
import { SVGProps } from 'react';

const SvgDiscountEmptyIcon = (props: SVGProps) => {
  return (
    <svg
      width={52}
      height={52}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)" fill="#fff">
        <path d="M24.375 36.833H5.958A5.963 5.963 0 0 1 0 30.875v-5.958c0-.897.728-1.625 1.625-1.625h1.083A3.796 3.796 0 0 0 6.5 19.5c0-2.09-1.7-3.792-3.792-3.792H1.625A1.626 1.626 0 0 1 0 14.083V8.125a5.963 5.963 0 0 1 5.958-5.958h35.75a5.963 5.963 0 0 1 5.959 5.958v5.958c0 .897-.728 1.625-1.625 1.625h-1.084a3.796 3.796 0 0 0-3.791 3.792 3.76 3.76 0 0 0 1.111 2.68 3.77 3.77 0 0 0 2.678 1.11h1.083a1.626 1.626 0 0 1 0 3.25h-1.085a6.99 6.99 0 0 1-4.977-2.063 6.993 6.993 0 0 1-2.06-4.977c0-3.7 2.868-6.743 6.5-7.022V8.125a2.712 2.712 0 0 0-2.709-2.708H5.958A2.712 2.712 0 0 0 3.25 8.125v4.353a7.051 7.051 0 0 1 6.5 7.022c0 3.7-2.869 6.743-6.5 7.022v4.353a2.712 2.712 0 0 0 2.708 2.708h18.417a1.626 1.626 0 0 1 0 3.25Z" />
        <path d="M18.958 7.583a1.626 1.626 0 0 1-1.625-1.625V3.792a1.626 1.626 0 0 1 3.25 0v2.166c0 .897-.728 1.625-1.625 1.625Zm0 20.835a1.626 1.626 0 0 1-1.625-1.625v-4.166a1.626 1.626 0 0 1 3.25 0v4.166c0 .897-.728 1.625-1.625 1.625Zm0-10.417a1.626 1.626 0 0 1-1.625-1.625v-4.167a1.626 1.626 0 0 1 3.25 0v4.167c0 .897-.728 1.625-1.625 1.625Zm0 18.832a1.626 1.626 0 0 1-1.625-1.625v-2.166a1.626 1.626 0 0 1 3.25 0v2.166c0 .897-.728 1.625-1.625 1.625Zm15.708 0a4.338 4.338 0 0 1-4.333-4.333 4.338 4.338 0 0 1 4.333-4.333A4.338 4.338 0 0 1 39 32.5a4.338 4.338 0 0 1-4.334 4.333Zm0-5.416a1.084 1.084 0 1 0 .001 2.167 1.084 1.084 0 0 0 0-2.167Zm13 18.416a4.338 4.338 0 0 1-4.333-4.333 4.338 4.338 0 0 1 4.333-4.333A4.338 4.338 0 0 1 52 45.5a4.338 4.338 0 0 1-4.334 4.333Zm0-5.416a1.084 1.084 0 1 0 .001 2.167 1.084 1.084 0 0 0 0-2.167Zm-13.541 5.416a1.623 1.623 0 0 1-1.29-2.61L46.92 28.805a1.624 1.624 0 1 1 2.58 1.974L35.416 49.196a1.63 1.63 0 0 1-1.291.637Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h52v52H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgDiscountEmptyIcon;
