import * as React from 'react';
import { SVGProps } from 'react';

const SvgAccountIcon = (props: SVGProps) => {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M14 28c7.732 0 14-6.268 14-14S21.732 0 14 0 0 6.268 0 14s6.268 14 14 14Z"
          fill="url(#b)"
        />
        <path
          d="M20.667 22.333H19v-1.666a2.5 2.5 0 0 0-2.5-2.5h-5a2.5 2.5 0 0 0-2.5 2.5v1.666H7.333v-1.666A4.167 4.167 0 0 1 11.5 16.5h5a4.167 4.167 0 0 1 4.167 4.167v1.666ZM14 14.833a5 5 0 1 1 0-10 5 5 0 0 1 0 10Zm0-1.666A3.333 3.333 0 1 0 14 6.5a3.333 3.333 0 0 0 0 6.667Z"
          fill="#000"
        />
      </g>
      <defs>
        <linearGradient
          id="b"
          x1={4.155}
          y1={0}
          x2={25.497}
          y2={29.436}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F08C2F" />
          <stop offset={1} stopColor="#A94B17" />
        </linearGradient>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h28v28H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgAccountIcon;
