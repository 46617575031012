import * as React from 'react';
import { SVGProps } from 'react';

const SvgDownArrowIcon = (props: SVGProps) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m7 10 5 5 5-5H7Z" fill="#fff" />
    </svg>
  );
};
export default SvgDownArrowIcon;
