import * as React from 'react';
import { SVGProps } from 'react';

const SvgExchangeIcon = (props: SVGProps) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#E4EFEF"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="svg-fill"
        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10Zm0-2a8 8 0 1 0 0-16.001A8 8 0 0 0 12 20Zm-5-7h9v2h-4v3l-5-5Zm5-4V6l5 5H8V9h4Z"
      />
    </svg>
  );
};
export default SvgExchangeIcon;
