import * as React from 'react';
import { SVGProps } from 'react';

const SvgErrorIcon = (props: SVGProps) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={8} cy={8} r={7.5} fill="#DB3247" stroke="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.096 5.347a.335.335 0 0 1 .471-.003L8 7.718l2.433-2.374a.335.335 0 0 1 .471.003c.13.13.128.34-.004.468L8.405 8.25l2.495 2.435a.328.328 0 0 1 .004.468.335.335 0 0 1-.471.003L8 8.782l-2.433 2.374a.335.335 0 0 1-.471-.003.328.328 0 0 1 .003-.468L7.595 8.25 5.1 5.815a.328.328 0 0 1-.004-.468Z"
        fill="#fff"
      />
    </svg>
  );
};
export default SvgErrorIcon;
