import * as React from 'react';
import { SVGProps } from 'react';

const SvgVerifyFactor7X = (props: SVGProps) => {
  return (
    <svg
      width={36}
      height={36}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 34c8.837 0 16-7.163 16-16S26.837 2 18 2 2 9.163 2 18s7.163 16 16 16Z"
        fill="#37F3FF"
      />
      <path d="M18 35c9.389 0 17-7.611 17-17S27.389 1 18 1 1 8.611 1 18s7.611 17 17 17Z" />
      <path
        d="m32.063 12.86-8.694-1.278-3.886-7.966a1.095 1.095 0 0 0-1.965 0l-3.887 7.966-8.694 1.278a1.09 1.09 0 0 0-.626.322 1.116 1.116 0 0 0 .02 1.568l6.29 6.2-1.486 8.755a1.118 1.118 0 0 0 .435 1.084 1.087 1.087 0 0 0 1.154.083l7.776-4.134 7.776 4.134c.212.114.459.152.695.11.596-.103.997-.674.894-1.277l-1.486-8.755 6.29-6.2a1.11 1.11 0 0 0 .318-.634 1.102 1.102 0 0 0-.924-1.256Z"
        fill="#9BF9FF"
      />
      <path
        d="m12.023 14.547 1.399 2.57 1.437-2.57h2.399l-2.39 4.133 2.5 4.32H14.96l-1.524-2.719L11.915 23H9.508l2.5-4.32-2.39-4.133h2.405Zm13.961-2.922v1.21L21.586 23H19.21l4.398-9.617h-5.664v-1.758h8.04Z"
        fill="#00555A"
      />
    </svg>
  );
};
export default SvgVerifyFactor7X;
