import * as React from 'react';
import { SVGProps } from 'react';

const SvgNotVerifyFactor2X = (props: SVGProps) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
        fill="#DCDCDC"
      />
      <path d="M12 23.25c6.213 0 11.25-5.037 11.25-11.25S18.213.75 12 .75.75 5.787.75 12 5.787 23.25 12 23.25Z" />
      <path
        d="m7.518 9.66 1.048 1.928L9.645 9.66h1.798l-1.793 3.1L11.525 16H9.721l-1.143-2.04L7.436 16H5.63l1.875-3.24-1.793-3.1h1.805Zm10.511 5.022V16h-5.836v-1.125l2.76-2.959c.277-.309.496-.58.656-.814.16-.239.276-.452.346-.64.074-.19.111-.372.111-.544 0-.258-.043-.478-.128-.662a.96.96 0 0 0-.381-.434 1.137 1.137 0 0 0-.61-.152c-.257 0-.48.062-.668.187a1.194 1.194 0 0 0-.422.522c-.093.223-.14.475-.14.756h-1.694c0-.508.122-.973.364-1.395a2.664 2.664 0 0 1 1.025-1.013c.441-.254.965-.381 1.57-.381.598 0 1.102.097 1.512.293.414.191.727.468.938.832.215.36.322.789.322 1.289 0 .281-.045.556-.135.826-.09.266-.219.531-.387.797a6.415 6.415 0 0 1-.597.797c-.235.27-.494.549-.78.838l-1.482 1.664h3.656Z"
        fill="#5A5A5A"
      />
    </svg>
  );
};
export default SvgNotVerifyFactor2X;
