import * as React from 'react';
import { SVGProps } from 'react';

const SvgCrossArrowsIcon = (props: SVGProps) => {
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={50} height={50} rx={12} fill="#404865" />
      <path
        d="m21.258 29.451 1.022-1.668a.848.848 0 0 0-.272-1.165.83.83 0 0 0-1.152.276l-1.023 1.668c-1.854 3.026-4.499 4.762-7.266 4.762H8.832a.837.837 0 0 0-.832.848c0 .469.375.848.832.848h3.742c3.36-.014 6.523-2.04 8.684-5.569Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m37.558 10.224 4.187 3.7a.702.702 0 0 1 .007 1.077l-4.194 3.7a.92.92 0 0 1-.613.224.92.92 0 0 1-.614-.223.697.697 0 0 1 0-1.076l2.721-2.401h-4.004c-2.862 0-5.597 1.56-7.514 4.291l-.649.927a.897.897 0 0 1-.733.36.964.964 0 0 1-.451-.111c-.409-.224-.536-.69-.282-1.051l.648-.927c2.235-3.184 5.513-5.013 8.981-5.013h4.011L36.34 11.3a.697.697 0 0 1 0-1.076.942.942 0 0 1 1.219 0Zm2.348 4.235v.013l.014-.013-.014-.012v.012ZM37.019 39a.868.868 0 0 1-.604-.237.773.773 0 0 1 0-1.128s2.671-2.546 2.664-2.546h-3.808c-3.413 0-6.64-1.94-8.838-5.324l-6.258-9.61c-1.887-2.89-4.586-4.552-7.395-4.552H8.846c-.464 0-.846-.363-.846-.811 0-.449.375-.812.846-.812h3.94c3.414 0 6.64 1.933 8.839 5.317l6.258 9.61c1.886 2.897 4.585 4.559 7.395 4.559H39.1l-2.678-2.546a.777.777 0 0 1 0-1.141.877.877 0 0 1 1.2 0l4.128 3.918a.777.777 0 0 1 0 1.14l-4.127 3.926a.868.868 0 0 1-.604.237Zm2.928-4.73-.014-.013v.027l.014-.013Z"
        fill="#fff"
      />
    </svg>
  );
};
export default SvgCrossArrowsIcon;
