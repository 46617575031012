import * as React from 'react';
import { SVGProps } from 'react';

const SvgRequestIcon = (props: SVGProps) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 2.25a.75.75 0 0 1 .75.75v4a.25.25 0 0 0 .25.25h4a.75.75 0 0 1 0 1.5h-4A1.75 1.75 0 0 1 14.25 7V3a.75.75 0 0 1 .75-.75Z"
        fill="#FEFEFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 3.75A1.25 1.25 0 0 0 9.75 5v10A1.25 1.25 0 0 0 11 16.25h7A1.25 1.25 0 0 0 19.25 15V8.31l-4.56-4.56H11Zm-1.945-.695A2.75 2.75 0 0 1 11 2.25h4a.75.75 0 0 1 .53.22l5 5c.141.14.22.331.22.53v7A2.75 2.75 0 0 1 18 17.75h-7A2.75 2.75 0 0 1 8.25 15V5c0-.73.29-1.429.805-1.945Z"
        fill="#FEFEFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 7.75A1.25 1.25 0 0 0 5.75 9v10A1.25 1.25 0 0 0 7 20.25h7A1.25 1.25 0 0 0 15.25 19v-2a.75.75 0 0 1 1.5 0v2A2.75 2.75 0 0 1 14 21.75H7A2.75 2.75 0 0 1 4.25 19V9A2.75 2.75 0 0 1 7 6.25h2a.75.75 0 0 1 0 1.5H7Z"
        fill="#FEFEFE"
      />
    </svg>
  );
};
export default SvgRequestIcon;
