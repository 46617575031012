import * as React from 'react';
import { SVGProps } from 'react';

const SvgVerifyFactor6X = (props: SVGProps) => {
  return (
    <svg
      width={36}
      height={36}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={18}
        cy={18}
        r={17}
        fill="#8FDEFF"
        stroke="#fff"
        strokeWidth={2}
      />
      <path
        d="m32.063 12.86-8.694-1.278-3.886-7.966a1.095 1.095 0 0 0-1.965 0l-3.887 7.966-8.694 1.278a1.09 1.09 0 0 0-.626.322 1.116 1.116 0 0 0 .02 1.568l6.29 6.2-1.486 8.755a1.117 1.117 0 0 0 .435 1.084 1.087 1.087 0 0 0 1.154.083l7.776-4.134 7.776 4.134c.212.114.459.152.695.11.596-.103.997-.674.894-1.277l-1.486-8.755 6.29-6.2a1.11 1.11 0 0 0 .318-.634 1.102 1.102 0 0 0-.924-1.256Z"
        fill="#C4EDFF"
      />
      <path
        d="m12.023 14.547 1.399 2.57 1.437-2.57h2.399l-2.39 4.133 2.5 4.32H14.96l-1.524-2.719L11.915 23H9.508l2.5-4.32-2.39-4.133h2.405Zm12.04-3.04h.328v1.798h-.133c-.594 0-1.123.088-1.586.265-.464.177-.857.43-1.18.758a3.297 3.297 0 0 0-.734 1.172c-.167.458-.25.971-.25 1.54v1.89c0 .406.039.765.117 1.078.083.307.2.565.352.773.156.203.338.357.546.461.209.1.443.149.704.149.244 0 .466-.052.664-.157.203-.104.375-.25.515-.437a2.02 2.02 0 0 0 .328-.672c.078-.26.118-.544.118-.852 0-.307-.04-.59-.118-.851a1.926 1.926 0 0 0-.328-.672 1.503 1.503 0 0 0-.531-.445 1.55 1.55 0 0 0-.703-.157 1.795 1.795 0 0 0-1.563.875 1.682 1.682 0 0 0-.242.805l-.68-.437c.016-.401.1-.782.25-1.141.157-.36.37-.677.641-.953.276-.281.607-.5.992-.656a3.35 3.35 0 0 1 1.305-.243c.531 0 .997.102 1.398.305.407.203.745.482 1.016.836.27.354.474.763.61 1.227.135.463.203.958.203 1.484 0 .552-.092 1.065-.274 1.54a3.601 3.601 0 0 1-.773 1.241 3.5 3.5 0 0 1-1.203.828 4.021 4.021 0 0 1-1.579.297c-.604 0-1.153-.11-1.648-.328a3.75 3.75 0 0 1-1.266-.937 4.245 4.245 0 0 1-.82-1.43 5.604 5.604 0 0 1-.281-1.813v-.875c0-.916.133-1.755.398-2.515a5.793 5.793 0 0 1 1.164-1.985 5.175 5.175 0 0 1 1.828-1.304c.714-.307 1.519-.461 2.415-.461Z"
        fill="#005071"
      />
    </svg>
  );
};
export default SvgVerifyFactor6X;
