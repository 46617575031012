import * as React from 'react';
import { SVGProps } from 'react';

const SvgFactor5X = (props: SVGProps) => {
  return (
    <svg
      width={36}
      height={36}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M18 34c8.837 0 16-7.163 16-16S26.837 2 18 2 2 9.163 2 18s7.163 16 16 16Z"
          fill="#F2CD2C"
        />
        <path d="M18 35c9.389 0 17-7.611 17-17S27.389 1 18 1 1 8.611 1 18s7.611 17 17 17Z" />
        <path
          d="m12.023 14.547 1.399 2.57 1.437-2.57h2.399l-2.39 4.133 2.5 4.32H14.96l-1.524-2.719L11.915 23H9.508l2.5-4.32-2.39-4.133h2.405Zm8.352 3.273-1.797-.43.649-5.765h6.39v1.82h-4.539l-.281 2.524c.15-.089.38-.182.687-.281a3.198 3.198 0 0 1 1.032-.157c.552 0 1.041.086 1.468.258.427.172.79.422 1.086.75.302.328.532.73.688 1.203.156.474.234 1.01.234 1.61 0 .505-.078.986-.234 1.445-.156.453-.393.86-.711 1.219a3.318 3.318 0 0 1-1.195.836c-.48.203-1.047.304-1.704.304-.49 0-.963-.073-1.421-.218a3.903 3.903 0 0 1-1.227-.649 3.34 3.34 0 0 1-.867-1.039 3.147 3.147 0 0 1-.336-1.406h2.234c.032.323.115.601.25.836.14.229.326.406.555.53.23.126.497.188.805.188.286 0 .53-.054.734-.164.203-.109.367-.26.492-.453.125-.198.216-.427.274-.687.062-.266.093-.552.093-.86 0-.307-.036-.586-.109-.836a1.787 1.787 0 0 0-.336-.648 1.489 1.489 0 0 0-.578-.422 2.018 2.018 0 0 0-.805-.148c-.416 0-.74.065-.968.195-.224.13-.412.279-.563.445Z"
          fill="#544400"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgFactor5X;
